import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import Campaign             from "Utils/Campaign";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Campaign Dialogs
 * @returns {React.ReactElement}
 */
function CampaignDialogs() {
    const { campaignID, campaignTab } = Navigate.useParams();
    const navigate = Commons.useGoto();

    const { openDetails } = Store.useAction("core");

    const {
        elem, activateErrors, totalContacts,
        triggersDraft, optionsDraft,
    } = Store.useState("campaign");

    const {
        fetchElem, activateCampaign, pauseCampaign, completeCampaign,
        publishCampaign, clearCampaign, deleteCampaign,
    } = Store.useAction("campaign");

    const { action } = Store.useState("campaignState");
    const { setInitialData, setInActivate, setAction } = Store.useAction("campaignState");

    const { publishErrors : flowPublishErrors } = Store.useState("flowEditor");
    const { setInPublish : setFlowInPublish } = Store.useAction("flowState");


    // Handles the Activate
    const handleActivate = async () => {
        setAction();
        const result = await activateCampaign(campaignID);
        if (result.success) {
            setInActivate(false);
            setFlowInPublish(false);
            fetchElem(campaignID);
        }
    };

    // Handles the Pause
    const handlePause = async () => {
        setAction();
        const result = await pauseCampaign(campaignID);
        if (result.success) {
            fetchElem(campaignID);
        }
    };

    // Handles the Complete
    const handleComplete = async () => {
        setAction();
        const result = await completeCampaign(campaignID);
        if (result.success) {
            fetchElem(campaignID);
        }
    };

    // Handles the Publish
    const handlePublish = async () => {
        setAction();
        const result = await publishCampaign(campaignID);
        if (result.success) {
            setInActivate(false);
            setFlowInPublish(false);
            fetchElem(campaignID);
        }
    };

    // Handles the Clear
    const handleClear = async () => {
        setAction();
        const result = await clearCampaign(campaignID);
        if (result.success) {
            const { elem, optionsDraft } = await fetchElem(campaignID);
            setInitialData(elem.name, optionsDraft, elem.isDraft);
        }
    };

    // Handles the Delete
    const handleDelete = async () => {
        setAction();
        const result = await deleteCampaign(campaignID);
        if (result.success) {
            navigate("CAMPAIGNS");
        }
    };

    // Goes to the Errors
    const handleErrors = () => {
        setAction();
        if (!Utils.isEmpty(activateErrors)) {
            if (!Navigate.isUrl("EDIT", campaignTab)) {
                navigate("CAMPAIGNS", campaignID, "EDIT");
            }
        } else if (flowPublishErrors.length) {
            if (!Navigate.isUrl("FLOW_ONE", campaignTab)) {
                navigate("CAMPAIGNS", campaignID, "FLOW_ONE");
            } else {
                openDetails();
            }
        }
    };


    // Returns the Activate Message
    const sendText = React.useMemo(() => {
        const sendOnce     = Campaign.willSendOnce(triggersDraft);
        const sendMulti    = Campaign.willSendMultiple(triggersDraft);
        const sendSMS      = Campaign.willSendSMS(optionsDraft);
        const sendWhatsApp = Campaign.willSendWhatsApp(optionsDraft);

        let result = "";
        if (sendMulti) {
            result += NLS.format("CAMPAIGNS_ACTIVATE_SEND_MULTI", totalContacts);
        } else if (sendOnce) {
            result += NLS.format("CAMPAIGNS_ACTIVATE_SEND_NOW", totalContacts);
        }
        if (sendSMS) {
            result += NLS.get("CAMPAIGNS_ACTIVATE_SEND_SMS");
        }
        if (sendWhatsApp) {
            result += NLS.get("CAMPAIGNS_ACTIVATE_SEND_WHATSAPP");
        }
        return result;
    }, [ totalContacts, JSON.stringify(triggersDraft) ]);

    // Returns the Activate Message
    const activateText = React.useMemo(() => {
        return NLS.format("CAMPAIGNS_ACTIVATE_TEXT", elem.name) + sendText;
    }, [ elem.name, sendText ]);

    // Returns the Publish Message
    const publishText = React.useMemo(() => {
        return NLS.format("CAMPAIGNS_PUBLISH_CHANGES", elem.name) + sendText;
    }, [ elem.name, sendText ]);

    // Returns the Error Message
    const errorText = React.useMemo(() => {
        if (!Utils.isEmpty(activateErrors)) {
            return "CAMPAIGNS_ERRORS_TEXT";
        }
        return "CAMPAIGNS_ERRORS_FLOW";
    }, [ JSON.stringify(activateErrors) ]);



    // Do the Render
    return <>
        <ConfirmDialog
            open={action.isActivate}
            icon="check"
            title="CAMPAIGNS_ACTIVATE_TITLE"
            message={activateText}
            onSubmit={handleActivate}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPause}
            icon="pause"
            title="CAMPAIGNS_PAUSE_TITLE"
            message="CAMPAIGNS_PAUSE_TEXT"
            content={elem.name}
            onSubmit={handlePause}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isComplete}
            icon="check"
            title="CAMPAIGNS_COMPLETE_TITLE"
            message="CAMPAIGNS_COMPLETE_TEXT"
            content={elem.name}
            onSubmit={handleComplete}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={publishText}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="CAMPAIGNS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CAMPAIGNS_DELETE_TITLE"
            message="CAMPAIGNS_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />

        <AlertDialog
            open={action.isError}
            icon="error"
            title="CAMPAIGNS_ERRORS_TITLE"
            message={errorText}
            onClose={handleErrors}
        />
    </>;
}

export default CampaignDialogs;
