import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import AuthContainer        from "Components/Utils/Auth/AuthContainer";
import AuthHeader           from "Components/Utils/Auth/AuthHeader";
import AuthForm             from "Components/Utils/Auth/AuthForm";



/**
 * The Authorized Page
 * @returns {React.ReactElement}
 */
function AuthorizedPage() {
    const { code } = Navigate.useParams();

    const navigate = Navigate.useGoto();


    // Do the Render
    const key = String(code || "").toUpperCase();

    return <AuthContainer>
        <AuthHeader
            title={`AUTHORIZED_${key}_TITLE`}
            help={`AUTHORIZED_${key}_TEXT`}
        />
        <AuthForm
            link="AUTHORIZED_CLOSE"
            button="AUTHORIZED_BUTTON"
            onSubmit={() => navigate("LOGIN")}
            onClick={() => window.close()}
        />
    </AuthContainer>;
}

export default AuthorizedPage;
