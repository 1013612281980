import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Dashboard
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageButtons          from "Dashboard/Components/Page/PageButtons";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import Alert                from "Dashboard/Components/Form/Alert";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Code = Styled(Html)`
    margin: 8px 0 32px;
    padding: 16px;
    font-size: 12px;
    background-color: var(--lightest-gray);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;



/**
 * The Hotel Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HotelEditor(props) {
    const {
        isEdit, fields, data, errors,
        onChange, onFieldChange, onSubmit, onDelete, onClose,
    } = props;

    const {
        elem, canAddHospitality, pmsProviders,
        canAddBookingEngine, bookingProviders,
        defaultFields, hospitalityFields, contactFields, statuses,
    } = Store.useState("hotel");

    const { isAnyAdmin } = Store.useState("auth");
    const { hasHospitality, hasBookingEngine } = Store.useState("permission");


    // Variables
    const pmsProvider          = Utils.getValue(pmsProviders, "key", data.pmsProvider);
    const pmsInputs            = pmsProvider?.inputs ?? [];

    const hasPMSProvider       = Boolean(data.pmsProvider);
    const pmsAllowsFTP         = Boolean(hasPMSProvider && pmsProvider.allowsFTP);
    const pmsUsesFTP           = Boolean(pmsAllowsFTP && data.pmsUseFTP);
    const hasPMSAdmin          = Boolean(hasPMSProvider && isAnyAdmin);
    const hasDefaultFields     = Boolean(hasPMSProvider && pmsProvider.reqDefaultFields);
    const hasHospitalityFields = Boolean(hasPMSProvider && hospitalityFields.length);
    const hasContactFields     = Boolean(hasPMSProvider && contactFields.length);
    const hasPMSError          = Boolean(hasDefaultFields && data.pmsErrorTime);
    const hasPMS               = hasHospitality && (canAddHospitality || (isEdit && elem.pmsProvider));
    const hasFields            = hasPMS && (hasDefaultFields || hasHospitalityFields || hasContactFields);

    const bookingProvider      = Utils.getValue(bookingProviders, "key", data.bookingProvider);
    const hasBookingEngineSet  = Boolean(data.bookingProvider);
    const hasBookingToken      = Boolean(hasBookingEngineSet && bookingProvider.requiresToken);
    const hasBookingRefresh    = Boolean(hasBookingEngineSet && bookingProvider.requiresRefresh);
    const hasBookingUrl        = Boolean(hasBookingEngineSet && bookingProvider.hasUrl);
    const reqBookingUrl        = Boolean(hasBookingEngineSet && bookingProvider.requiresUrl);
    const hasBookingPromoCode  = Boolean(hasBookingEngineSet && bookingProvider.hasPromoCode);
    const hasBooking           = hasBookingEngine && (canAddBookingEngine || (isEdit && elem.bookingProvider));


    // Returns the Error Count in the Step
    const getErrorCount = (step) => {
        let result = Commons.getErrorCount(fields, errors, step);
        if (step === "fields" && hasPMSError) {
            result += 1;
        }
        return result;
    };


    // Do the Render
    return <PageContainer error={errors.form}>
        <PageAccordion initial="main" noClose>
            <AccordionItem
                value="main"
                message="GENERAL_MAIN"
                description="HOTELS_MAIN_TEXT"
                errorCount={getErrorCount("main")}
                withGap
            >
                <InputField
                    name="name"
                    label="GENERAL_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    name="siteUrl"
                    label="HOTELS_SITE_URL"
                    value={data.siteUrl}
                    error={errors.siteUrl}
                    onChange={onChange}
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={onChange}
                    hideClear
                    isRequired
                />
            </AccordionItem>

            <AccordionItem
                isHidden={!hasPMS}
                value="pms"
                message="HOTELS_PMS_TITLE"
                description="HOTELS_PMS_TEXT"
                errorCount={getErrorCount("pms")}
                withGap
            >
                <InputField
                    type="select"
                    name="pmsProvider"
                    label="HOTELS_PMS_PROVIDER"
                    options={pmsProviders}
                    value={data.pmsProvider}
                    error={errors.pmsProvider}
                    onChange={onChange}
                    noneText="GENERAL_NONE_FEM"
                    shrinkLabel
                />

                <InputField
                    isHidden={!pmsAllowsFTP}
                    type="toggle"
                    name="pmsUseFTP"
                    label="HOTELS_USE_FTP"
                    value={data.pmsUseFTP}
                    error={errors.pmsUseFTP}
                    onChange={onChange}
                    withBorder
                />
                <InputField
                    isHidden={!hasPMSProvider}
                    name="pmsID"
                    label={pmsUsesFTP ? "HOTELS_FTP_USERNAME" : "HOTELS_PMS_ID"}
                    value={data.pmsID}
                    error={errors.pmsID}
                    onChange={onChange}
                    generateCode
                    isRequired
                />
                <InputField
                    isHidden={!pmsUsesFTP}
                    name="pmsPassword"
                    label="HOTELS_FTP_PASSWORD"
                    value={data.pmsPassword}
                    error={errors.pmsPassword}
                    onChange={onChange}
                    generateCode
                    isRequired
                />
                <>
                    {pmsInputs.map((item) => <InputField
                        {...item}
                        key={item.name}
                        type={item.type}
                        value={data[item.name] ?? ""}
                        error={errors[item.name] ?? ""}
                        onChange={onChange}
                    />)}
                </>
                <InputField
                    isHidden={!hasPMSAdmin}
                    type="toggle"
                    name="pmsLogAll"
                    label="HOTELS_PMS_LOG_ALL"
                    value={data.pmsLogAll}
                    error={errors.pmsLogAll}
                    onChange={onChange}
                    withBorder
                />
                <InputField
                    isHidden={!hasPMSAdmin}
                    type="toggle"
                    name="pmsIsDisabled"
                    label="HOTELS_PMS_IS_DISABLED"
                    value={data.pmsIsDisabled}
                    error={errors.pmsIsDisabled}
                    onChange={onChange}
                    withBorder
                />
            </AccordionItem>

            <AccordionItem
                isHidden={!hasFields}
                value="fields"
                message="HOTELS_FIELDS_TITLE"
                description="HOTELS_FIELDS_TEXT"
                errorCount={getErrorCount("fields")}
            >
                {hasPMSError && <>
                    <Alert
                        variant="error"
                        message="HOTELS_FIELDS_ERRORS"
                    />
                    <Code
                        content={Commons.jsonToHtml(elem.pmsErrorEntry)}
                    />
                </>}

                <Columns isHidden={!hasFields}>
                    <>
                        {defaultFields.map(({ key, value }) => <InputField
                            isHidden={!hasDefaultFields}
                            key={key}
                            name={String(key)}
                            label={value}
                            value={data.fields[key] || ""}
                            error={errors[`field-${key}`] || ""}
                            onChange={onFieldChange}
                        />)}
                        {hospitalityFields.map(({ key, value }) => <InputField
                            isHidden={!hasHospitalityFields}
                            key={key}
                            name={String(key)}
                            label={value}
                            value={data.fields[key] || ""}
                            error={errors[`field-${key}`] || ""}
                            onChange={onFieldChange}
                        />)}
                        {contactFields.map(({ key, value }) => <InputField
                            isHidden={!hasContactFields}
                            key={key}
                            name={String(key)}
                            label={value}
                            value={data.fields[key] || ""}
                            error={errors[`field-${key}`] || ""}
                            onChange={onFieldChange}
                        />)}
                    </>
                </Columns>
            </AccordionItem>

            <AccordionItem
                isHidden={!hasBooking}
                value="booking"
                message="HOTELS_BOOKING_TITLE"
                description="HOTELS_BOOKING_TEXT"
                errorCount={getErrorCount("booking")}
                withGap
            >
                <InputField
                    type="select"
                    name="bookingProvider"
                    label="HOTELS_BOOKING_ENGINE"
                    options={bookingProviders}
                    value={data.bookingProvider}
                    error={errors.bookingProvider}
                    onChange={onChange}
                    noneText="GENERAL_NONE"
                    shrinkLabel
                />
                <InputField
                    isHidden={!hasBookingEngineSet}
                    name="bookingHotel"
                    label="HOTELS_BOOKING_HOTEL"
                    value={data.bookingHotel}
                    error={errors.bookingHotel}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    isHidden={!hasBookingToken}
                    name="bookingAccessToken"
                    label="HOTELS_BOOKING_TOKEN"
                    value={data.bookingAccessToken}
                    error={errors.bookingAccessToken}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    isHidden={!hasBookingRefresh}
                    name="bookingRefreshToken"
                    label="HOTELS_BOOKING_REFRESH"
                    value={data.bookingRefreshToken}
                    error={errors.bookingRefreshToken}
                    onChange={onChange}
                    isRequired
                />
                <InputField
                    isHidden={!hasBookingUrl}
                    name="bookingUrl"
                    label="HOTELS_BOOKING_URL"
                    value={data.bookingUrl}
                    error={errors.bookingUrl}
                    onChange={onChange}
                    isRequired={reqBookingUrl}
                />
                <InputField
                    isHidden={!hasBookingPromoCode}
                    name="bookingPromoCode"
                    label="HOTELS_BOOKING_PROMO_CODE"
                    value={data.bookingPromoCode}
                    error={errors.bookingPromoCode}
                    onChange={onChange}
                />
            </AccordionItem>
        </PageAccordion>

        <PageButtons
            isEdit={isEdit}
            onSubmit={onSubmit}
            onDelete={onDelete}
            onClose={onClose}
        />
    </PageContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HotelEditor.propTypes = {
    isEdit        : PropTypes.bool.isRequired,
    fields        : PropTypes.object.isRequired,
    data          : PropTypes.object.isRequired,
    errors        : PropTypes.object.isRequired,
    onChange      : PropTypes.func.isRequired,
    onFieldChange : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    onDelete      : PropTypes.func.isRequired,
    onClose       : PropTypes.func.isRequired,
};

export default HotelEditor;
