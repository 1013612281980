import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import FieldsItems          from "./FieldsItems";
import ChannelIconList      from "Components/Utils/Common/ChannelIconList";
import ColorList            from "Components/Utils/Common/ColorList";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";



/**
 * The Contact Detail Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactItem(props) {
    const { elem, collapsible, action, onAction, canEdit } = props;

    const { hasMarketing } = Store.useState("permission");

    const hasContact   = Boolean(elem.id);
    const hasProviders = Boolean(elem.id && elem.providerItems && elem.providerItems.length);


    // Do the Render
    return <DetailList
        isHidden={!hasContact}
        message="CONTACTS_SINGULAR"
        collapsible={collapsible}
        action={action}
        onAction={onAction}
        canEdit={canEdit}
    >
        {hasProviders && <DetailItem
            icon="channel"
            tooltip="CHANNELS_NAME"
        >
            <ChannelIconList items={elem.providerItems} />
        </DetailItem>}

        <DetailItem
            icon="name"
            message={elem.fullName}
            tooltip="GENERAL_FULL_NAME"
        />
        <DetailItem
            icon="language"
            message={elem.languageName}
            tooltip="LANGUAGES_SINGULAR"
        />
        <DetailItem
            icon="origin"
            message={elem.originName}
            tooltip="GENERAL_ORIGIN"
        />
        <DetailItem
            icon="email"
            message={elem.emails}
            tooltip="GENERAL_EMAIL"
        />
        <DetailItem
            icon="phone"
            message={elem.cellphones}
            tooltip="GENERAL_CELLPHONE"
        />
        <DetailItem
            icon="instagram"
            message={elem.instagram}
            tooltip="CONTACTS_INSTAGRAM"
        />
        <DetailItem
            icon="telegram"
            message={elem.telegram}
            tooltip="CONTACTS_TELEGRAM"
        />
        <DetailItem
            icon="address"
            message={elem.address}
            tooltip="CONTACTS_ADDRESS"
        />
        <FieldsItems
            fields={elem.visibleFields}
        />
        <DetailItem
            icon="id"
            message={elem.externalID}
            tooltip="GENERAL_EXTERNAL_ID"
            prefix="GENERAL_CODE"
        />
        <DetailItem
            isHidden={!hasMarketing}
            icon="status"
            message={elem.dontSendText}
        />
        <DetailItem
            isHidden={!elem.hasTags}
            icon="tag"
            tooltip="TAGS_SINGULAR"
        >
            <ColorList list={elem.tagList} />
        </DetailItem>
        <DetailItem
            icon="observations"
            message={elem.observations}
            tooltip="GENERAL_OBSERVATIONS"
        />
        <DetailItem
            className={elem.qualificationColor}
            icon={elem.qualificationIcon}
            message={elem.qualificationPercent}
            prefix="CONVERSATIONS_QUALIFICATION"
            tooltip={elem.qualificationTooltip}
        />
        <DetailItem
            icon="time"
            message={elem.createdDate}
            tooltip="GENERAL_CREATED_TIME"
        />
        <DetailItem
            className="text-red"
            icon="block"
            message={elem.isBlocked ? "CONTACTS_BLOCKED" : ""}
            tooltip="CONTACTS_BLOCKED"
        />
    </DetailList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactItem.propTypes = {
    elem        : PropTypes.object.isRequired,
    collapsible : PropTypes.string,
    action      : PropTypes.string,
    canEdit     : PropTypes.bool,
    onAction    : PropTypes.func,
};

export default ContactItem;
