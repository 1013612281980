import React                from "react";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Dashboard
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Campaign Executions
 * @returns {React.ReactElement}
 */
function CampaignExecutions() {
    const { fetch, load, loadFilter } = useList("campaignExecution", "CAMPAIGN");

    const data = Store.useState("campaignExecution");
    const { loading, list, total, filters, sort, canEdit } = data;

    const { deleteExecution } = Store.useAction("campaignExecution");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Returns true if the Delete is hidden
    const hideDelete = (elemID) => {
        const sentAmount = Utils.getValue(list, "clientID", elemID, "sentAmount");
        return sentAmount > 0;
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteExecution(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "executionID", elemID, "description");

    return <>
        <Filter
            onFilter={loadFilter}
            values={filters}
            hasSearch
        />
        <Table
            fetch={load}
            sort={sort}
            none="CAMPAIGNS_DISPATCHES_NONE_AVAILABLE"
            isLoading={loading}
            hasFilter
            hasTabs
        >
            <TableHead>
                <TableHeader field="description" message="GENERAL_DESCRIPTION"        isTitle />
                <TableHeader field="createdTime" message="GENERAL_CREATED"            />
                <TableHeader field="sendTime"    message="GENERAL_SEND_AT"            />
                <TableHeader field="sentAmount"  message="CAMPAIGNS_DISPATCHES_DONE"  align="center" maxWidth="100" />
                <TableHeader field="totalAmount" message="CAMPAIGNS_DISPATCHES_TOTAL" align="center" maxWidth="140" />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.executionID} elemID={elem.executionID}>
                    <TableCell message={elem.description} />
                    <TableCell message={elem.createdDate} />
                    <TableCell message={elem.sendDate}    />
                    <TableCell message={elem.sentAmount}  />
                    <TableCell message={elem.totalAmount} />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={startAction} canEdit={canEdit}>
                <TableAction action="DELETE" message="CAMPAIGNS_DISPATCHES_DELETE_TITLE" hide={hideDelete} />
            </TableActionList>
        </Table>

        <DeleteDialog
            open={action.isDelete}
            title="CAMPAIGNS_DISPATCHES_DELETE_TITLE"
            message="CAMPAIGNS_DISPATCHES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </>;
}

export default CampaignExecutions;
