import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// APIs
import {
    Ticket, TicketMessage,
} from "Utils/API";



// The initial State
const initialState = {
    loading          : true,
    error            : false,
    edition          : 0,
    canCreate        : false,
    canEdit          : false,
    canDelete        : false,
    canImport        : false,
    canExport        : false,
    list             : [],
    total            : 0,
    elem             : {},
    member           : {},
    messages         : [],
    canModify        : false,
    canOpen          : false,
    canWait          : false,
    canClose         : false,
    canAssign        : false,
    canNote          : false,
    ticketTypes      : [],
    waitlists        : [],
    userCredentials  : [],
    adminCredentials : [],
    admins           : [],
    filters          : {
        search   : "",
        fromDate : "",
        toDate   : "",
    },
    sort             : {
        filter   : "Open",
        orderBy  : "lastReplyTime",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "TICKET_LOADING" });
    },

    /**
     * Fetches the Ticket List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "PARTNER") {
            params.partnerID = elemID;
        } else if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data   = await Ticket.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "TICKET_LIST", data });
    },

    /**
     * Fetches a single Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @returns {Promise}
     */
    async fetchElem(dispatch, ticketID) {
        const data = await Ticket.getOne({ ticketID });
        dispatch({ type : "TICKET_ELEM", data });
    },

    /**
     * Fetches the Ticket Edit data
     * @param {Function} dispatch
     * @param {Number}   clientID
     * @param {Number}   partnerID
     * @param {Boolean=} isInitial
     * @returns {Promise}
     */
    async fetchEditData(dispatch, clientID, partnerID, isInitial = true) {
        const data = await Ticket.getEditData({ clientID, partnerID });
        data.isInitial = isInitial;
        dispatch({ type : "TICKET_EDIT", data });
    },

    /**
     * Creates a Ticket
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    createTicket(dispatch, data) {
        return Ticket.create(data);
    },

    /**
     * Edits a Ticket
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editTicket(dispatch, data) {
        return Ticket.edit(data);
    },

    /**
     * Deletes a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @returns {Promise}
     */
    deleteTicket(dispatch, ticketID) {
        return Ticket.delete({ ticketID });
    },

    /**
     * Assigns a Ticket
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    assignTicket(dispatch, data) {
        return Ticket.assign(data);
    },

    /**
     * Opens a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @returns {Promise}
     */
    openTicket(dispatch, ticketID) {
        return Ticket.open({ ticketID });
    },

    /**
     * Waits a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Number}   ticketWaitlistID
     * @returns {Promise}
     */
    waitTicket(dispatch, ticketID, ticketWaitlistID) {
        return Ticket.wait({ ticketID, ticketWaitlistID });
    },

    /**
     * Closes a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @returns {Promise}
     */
    closeTicket(dispatch, ticketID) {
        return Ticket.close({ ticketID });
    },

    /**
     * Mutes a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @returns {Promise}
     */
    muteTicket(dispatch, ticketID) {
        return Ticket.mute({ ticketID });
    },

    /**
     * Un-mutes a Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @returns {Promise}
     */
    unmuteTicket(dispatch, ticketID) {
        return Ticket.unmute({ ticketID });
    },

    /**
     * Adds a Message to the Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Object}   params
     * @returns {Promise}
     */
    addMessage(dispatch, ticketID, params) {
        return TicketMessage.add({ ...params, ticketID });
    },

    /**
     * Edits a Message in the Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Number}   messageID
     * @param {String}   message
     * @returns {Promise}
     */
    editMessage(dispatch, ticketID, messageID, message) {
        return TicketMessage.edit({ ticketID, messageID, message });
    },

    /**
     * Deletes a Message from the Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Number}   messageID
     * @returns {Promise}
     */
    deleteMessage(dispatch, ticketID, messageID) {
        return TicketMessage.delete({ ticketID, messageID });
    },

    /**
     * Deletes an Image from the Ticket
     * @param {Function} dispatch
     * @param {Number}   ticketID
     * @param {Number}   messageID
     * @returns {Promise}
     */
    deleteImage(dispatch, ticketID, messageID) {
        return TicketMessage.deleteImage({ ticketID, messageID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.ticketNumber    = `#${elem.ticketID}`;
    elem.name            = `#${elem.ticketID} - ${elem.subject}`;
    elem.adminName       = elem.adminName || NLS.get("GENERAL_NOBODY");
    elem.lastReplyText   = DateTime.formatString(elem.lastReplyTime);
    return elem;
}

/**
 * Parses all the Messages
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseMessages(list) {
    for (const elem of list) {
        elem.createdDate   = DateTime.formatDate(elem.createdTime, "dashes");
        elem.createdString = DateTime.formatString(elem.createdTime);
    }
    return list;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "TICKET_LIST", "TICKET_ELEM", "TICKET_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "TICKET_LOADING":
        return {
            ...state,
            loading          : true,
        };

    case "TICKET_LIST":
        return {
            ...state,
            loading          : false,
            error            : false,
            canCreate        : action.data.canCreate,
            canEdit          : action.data.canEdit,
            canDelete        : action.data.canDelete,
            canImport        : action.data.canImport,
            canExport        : action.data.canExport,
            canAssign        : action.data.canAssign,
            list             : Utils.parseList(action.data.list, parseElem),
            total            : action.data.total,
            waitlists        : action.data.waitlists,
            filters          : action.data.filters,
            sort             : action.data.sort,
        };

    case "TICKET_ELEM":
        return {
            ...state,
            error            : false,
            edition          : state.edition + 1,
            elem             : parseElem(action.data.elem),
            messages         : parseMessages(action.data.messages),
            member           : action.data.member,
            canModify        : action.data.canModify,
            canOpen          : action.data.canOpen,
            canWait          : action.data.canWait,
            canClose         : action.data.canClose,
            canAssign        : action.data.canAssign,
            canNote          : action.data.canNote,
            ticketTypes      : action.data.ticketTypes,
            waitlists        : action.data.waitlists,
            userCredentials  : action.data.userCredentials,
            adminCredentials : action.data.adminCredentials,
            admins           : action.data.admins,
        };

    case "TICKET_EDIT":
        return {
            ...state,
            error            : false,
            edition          : state.edition + (action.data.isInitial ? 1 : 0),
            canAssign        : action.data.canAssign,
            ticketTypes      : action.data.ticketTypes,
            waitlists        : action.data.waitlists,
            userCredentials  : action.data.userCredentials,
            adminCredentials : action.data.adminCredentials,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
