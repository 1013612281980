import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import useList              from "Dashboard/Hooks/List";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";

// Components
import ConversationDetails  from "./ConversationDetails";
import ConversationHeader   from "./Actions/ConversationHeader";
import ListContainer        from "./List/ListContainer";
import ChatEmpty            from "./Chat/ChatEmpty";
import ChatContent          from "./Chat/ChatContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";



// Styles
const Container = Styled.div.attrs(({ isAdmin, showChat }) => ({ isAdmin, showChat }))`
    display: grid;
    grid-template-columns: var(--conversation-width) 2fr var(--details-width);
    gap: var(--main-gap);

    ${(props) => !props.showChat && `
        grid-template-columns: var(--conversation-width) 1fr;
    `}

    @media (max-width: 1300px) {
        ${(props) => (props.isAdmin && !props.showChat) && `
            grid-template-columns: var(--conversation-width) 1fr;
        `}
        ${(props) => (props.isAdmin && props.showChat) && `
            grid-template-columns: var(--conversation-width) 1fr var(--details-width);
        `}
    }

    @media (max-width: 1200px) {
        grid-template-columns: var(--conversation-width) 2fr;
    }

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
        display: block;
    }
`;



/**
 * The Conversation Page
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationPage(props) {
    const { type } = props;

    const { conversationTab, conversationHash } = Navigate.useParams();
    const conversationsNavigate = Conversations.useGoto();

    const { isAdmin } = Store.useState("auth");
    const { loading, modified } = Store.useState("conversation");
    const { fetch, loadTab } = useList("conversation", type, false);


    // The References
    const timerRef = React.useRef(0);
    const tabRef   = React.useRef("");

    // The Current State
    const [ update, setUpdate ] = React.useState(0);


    // Go to Queue and remove the Auto-update
    React.useEffect(() => {
        if (!conversationTab) {
            conversationsNavigate("QUEUE");
        }
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Handle the Tab and Update
    React.useEffect(() => {
        if (!conversationTab) {
            conversationsNavigate("QUEUE");
        } else if (!Conversations.isValidStatusUrl(conversationTab)) {
            conversationsNavigate("QUEUE", conversationTab);
        } else if (tabRef.current !== conversationTab) {
            load(conversationTab, true);
            tabRef.current = conversationTab;
        } else {
            load(conversationTab, false);
        }
    }, [ conversationTab, modified, update ]);


    // Loads the data and sets an auto-update
    const load = (conversationTab, isNew) => {
        if (isNew) {
            const status = Conversations.urlToStatus(conversationTab);
            loadTab(status, 20, false);
        } else {
            fetch();
        }

        Utils.setTimeout(timerRef, () => {
            setUpdate(update + 1);
        }, 15 * 1000);
    };


    // Variables
    const withNavigation   = type === "CLIENT";
    const isMobile         = Utils.isMobile();
    const showList         = Boolean(!isMobile || (isMobile && !conversationHash));
    const showConversation = Boolean(!isMobile || (isMobile && conversationHash));
    const showEmpty        = Boolean(showConversation && !conversationHash);
    const showChat         = Boolean(showConversation && conversationHash);


    // Do the Render
    return <Main withNavigation={withNavigation}>
        <ConversationHeader />
        <Content isLoading={loading}>
            <Container isAdmin={isAdmin} showChat={showChat}>
                {showList  && <ListContainer fetch={fetch} />}
                {showEmpty && <ChatEmpty />}
                {showChat  && <ChatContent />}
                {showChat  && <ConversationDetails />}
            </Container>
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationPage.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ConversationPage;
