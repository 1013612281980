import React                from "react";
import PropTypes            from "prop-types";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import ContactFilter        from "./Dialogs/ContactFilter";
import ContactEdit          from "./Dialogs/ContactEdit";
import ContactImport        from "./Dialogs/ContactImport";
import ContactCombine       from "./Dialogs/ContactCombine";
import ContactMerge         from "./Dialogs/ContactMerge";
import ContactBlock         from "./Dialogs/ContactBlock";
import ConversationCreate   from "Components/App/Client/Conversation/Actions/ConversationCreate";
import HospitalityEdit      from "Components/App/Client/Hospitality/Dialogs/HospitalityEdit";
import OrderEdit            from "Components/App/Client/Store/Order/OrderEdit";
import AccountEdit          from "Components/App/Client/Account/Dialogs/AccountEdit";
import AccountAssign        from "Components/App/Client/Account/Dialogs/AccountAssign";

// Dashboard
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Contact Dialogs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ContactDialogs(props) {
    const {
        action, elemID, startAction, endAction,
        fetch, loadFilter, mergeIDs, setMergeIDs, checked, setChecked,
    } = props;

    const { contactID } = Navigate.useParams();
    const clientID      = Commons.useClientID();
    const navigate      = Commons.useGoto();

    const { list, filters, elem, hospitality, order } = Store.useState("contact");
    const { fetchElem, removeElem, deleteContact, deleteContacts } = Store.useAction("contact");


    // Handles the Filter Submit
    const handleFilter = (filters) => {
        endAction();
        loadFilter(filters);
        removeElem();
    };

    // Handles the Edit Submit
    const handleEdit = (newContactID) => {
        triggerElemUpdate(newContactID);
        endAction();
    };

    // Handles the Action
    const handleAction = () => {
        triggerElemUpdate();
        endAction();
    };

    // Handles the Edit Close
    const handleClose = (update, newContactID) => {
        if (update) {
            triggerElemUpdate(newContactID);
        }
        endAction();
    };

    // Handles the Combine
    const handleCombine = (newContactIDs) => {
        setMergeIDs(newContactIDs);
        startAction("MERGE");
    };

    // Handles the Merge Submit
    const handleMerge = (response) => {
        setChecked([]);
        triggerElemUpdate(response.contactID);
        endAction();
    };

    // Handles the Single Delete Submit
    const handleDelete = async () => {
        const result = await deleteContact(elemID);
        if (result.success) {
            fetch();
            if (elemID === elem.id) {
                removeElem();
            }
        }
        endAction();
    };

    // Handles the Multi Delete Submit
    const handleMultiDelete = async () => {
        const result = await deleteContacts(checked);
        if (result.success || result.warning) {
            setChecked([]);
            fetch();
            if (checked.includes(elem.id)) {
                removeElem();
            }
        }
        endAction();
    };

    // Handles the Import Submit
    const handleImport = () => {
        fetch();
        endAction();
    };


    // Trigger a Contact Update
    const triggerElemUpdate = (newContactID) => {
        fetch();
        const elemID = newContactID || elem.id;
        if (elemID !== contactID) {
            navigate("CONTACTS", elemID);
        } else {
            fetchElem(elemID);
        }
    };


    // Variables
    const listElem = Utils.getValue(list, "contactID", elemID);
    const contact  = listElem.id ? listElem : elem;


    // Do the Render
    return <>
        <ContactFilter
            open={action.isFilter}
            filters={filters}
            clientID={clientID}
            onSubmit={handleFilter}
            onClose={endAction}
        />
        <ContactEdit
            open={action.isCE}
            elemID={action.isCreate ? 0 : elemID}
            clientID={clientID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <ContactImport
            open={action.isImport}
            clientID={clientID}
            onSubmit={handleImport}
            onClose={endAction}
        />
        <ContactCombine
            open={action.isCombine}
            contact={contact}
            onSubmit={handleCombine}
            onClose={endAction}
        />
        <ContactMerge
            open={action.isMerge}
            clientID={clientID}
            contactIDs={mergeIDs}
            onSubmit={handleMerge}
            onClose={endAction}
        />
        <ContactBlock
            open={action.isBlock}
            elem={contact}
            onSubmit={handleAction}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CONTACTS_DELETE_TITLE"
            message="CONTACTS_DELETE_TEXT"
            content={contact.fullName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isMultidel}
            title="CONTACTS_MULTI_DELETE_TITLE"
            message={NLS.pluralize("CONTACTS_MULTI_DELETE_TEXT", checked.length)}
            onSubmit={handleMultiDelete}
            onClose={endAction}
        />

        <ConversationCreate
            open={action.isConversation}
            clientID={clientID}
            contactID={elemID}
            onSubmit={handleAction}
            onClose={endAction}
        />
        <HospitalityEdit
            open={action.isHospitality}
            elemID={hospitality.id}
            clientID={clientID}
            onSubmit={handleAction}
            onClose={endAction}
        />
        <OrderEdit
            open={action.isOrder}
            elemID={order.id}
            onSubmit={handleAction}
            onClose={endAction}
        />
        <AccountAssign
            openAssign={action.isAssign}
            openUnassign={action.isUnassign}
            elemID={elemID}
            elemName={contact.fullName}
            contactID={contact.id}
            clientID={clientID}
            onSubmit={handleAction}
            onClose={handleClose}
        />
        <AccountEdit
            open={action.isAccount}
            elemID={elemID}
            clientID={clientID}
            onSubmit={handleAction}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ContactDialogs.propTypes = {
    action      : PropTypes.object.isRequired,
    elemID      : PropTypes.number,
    startAction : PropTypes.func.isRequired,
    endAction   : PropTypes.func.isRequired,
    fetch       : PropTypes.func.isRequired,
    loadFilter  : PropTypes.func.isRequired,
    mergeIDs    : PropTypes.array.isRequired,
    setMergeIDs : PropTypes.func.isRequired,
    checked     : PropTypes.array.isRequired,
    setChecked  : PropTypes.func.isRequired,
};

export default ContactDialogs;
