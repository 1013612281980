import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import InputField           from "Dashboard/Components/Form/InputField";
import EmojiPopup           from "Dashboard/Components/Form/EmojiPopup";



// Styles
const Container = Styled.div`
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 16px 8px;
    gap: 4px;
    background-color: var(--lightest-gray);
    border-top: 1px solid var(--border-color-light);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
`;

const Content = Styled.div`
    flex-grow: 2;
    margin: 0 4px;
`;
const PreContent = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding: 2px 4px 2px 8px;
    background-color: var(--lighter-gray);
    border-radius: var(--border-radius);
    border: 1px solid var(--lightest-color);
`;
const PreContentText = Styled.div`
    width: calc(100% - 16px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
const PreContentClose = Styled(IconLink)`
    --link-size: 20px;
    font-size: 12px;
`;

const Link = Styled(IconLink).attrs(({ withTabs }) => ({ withTabs }))`
    font-size: 18px;
    ${(props) => props.withTabs && "margin-top: var(--tabs-table);"}
`;

const Textarea = Styled(InputField)`
    flex-grow: 2;
`;

const FileInput = Styled.input`
    display: none;
`;



/**
 * The Ticket Reply
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TicketReply(props) {
    const { elemID, canNote, onSubmit } = props;

    const { showResult } = Store.useAction("core");
    const { fetchElem, addMessage } = Store.useAction("ticket");


    // The References
    const inputRef   = React.useRef(null);
    const messageRef = React.useRef(null);
    const emojiRef   = React.useRef(null);

    // The Initial Data
    const initialData = {
        message : "",
        file    : "",
        isNote  : 0,
    };
    const initialErrors = {
        message : "",
    };

    // The Current State
    const [ tab,       setTab       ] = React.useState("message");
    const [ sending,   setSending   ] = React.useState(false);
    const [ fileName,  setFileName  ] = React.useState("");
    const [ data,      setData      ] = React.useState({ ...initialData });
    const [ errors,    setErrors    ] = React.useState({ ...initialErrors });
    const [ emojiOpen, setEmojiOpen ] = React.useState(false);


    // Handles the Tab
    const handleTab = (value) => {
        setTab(value);
        setData({ ...data, isNote : value === "note" ? 1 : 0 });
    };

    // Handles the Input Change
    const handleChange = (name, value) => {
        setData({ ...data, [name] : value });
        setErrors({ ...errors, [name] : "" });
    };

    // Handles the Emoji Open
    const handleEmojiOpen = () => {
        setEmojiOpen(!emojiOpen);
    };

    // Handles the Emoji Click
    const handleEmojiClick = (emoji) => {
        const message = Utils.insertText(messageRef, data.message, emoji);
        handleChange("message", message);
        setEmojiOpen(false);
        messageRef.current.focus();
    };

    // Handles the File Upload
    const handleFileUpload = (e) => {
        e.preventDefault();
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // Handles the File Remove
    const handleFileRemove = (e) => {
        e.preventDefault();
        if (inputRef.current) {
            inputRef.current.value = "";
            setFileName("");
        }
    };

    // Handles the Input Change
    const handleInputChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const size = file.size / (1024 * 1024);
            if (size > 3) {
                showResult("error", "MEDIA_ERROR_UPLOAD_SIZE");
            } else {
                setFileName(file.name);
            }
        }
    };

    // Handles the Message Submit
    const handleMessageSubmit = async (e) => {
        e.preventDefault();
        if (sending || !data.message) {
            return;
        }
        const node = inputRef.current;

        setSending(false);
        setErrors({ ...initialErrors });
        try {
            if (node.files.length) {
                data.file = node.files[0];
            }
            await addMessage(elemID, data);
            await fetchElem(elemID);
            setSending(false);
            setFileName("");
            setData({ ...initialData });
            onSubmit();
            node.value = "";
        } catch (errors) {
            setSending(false);
            setErrors(errors);
        }
    };



    // Do the Render
    const forNote = canNote && tab === "note";

    return <>
        <Container>
            <Link
                passedRef={emojiRef}
                variant="light"
                icon="emoji"
                onClick={handleEmojiOpen}
                withTabs={canNote}
            />
            <Link
                variant="light"
                icon="attachment"
                onClick={handleFileUpload}
                onTouchEnd={handleFileUpload}
                withTabs={canNote}
            />

            <Content>
                <TabList
                    isHidden={!canNote}
                    selected={tab}
                    onClick={handleTab}
                >
                    <TabItem
                        message="GENERAL_MESSAGE"
                        value="message"
                    />
                    <TabItem
                        message="CONVERSATIONS_NOTES_SINGULAR"
                        value="note"
                    />
                </TabList>

                {!!fileName && <PreContent>
                    <PreContentText>{fileName}</PreContentText>
                    <PreContentClose
                        icon="close"
                        onClick={handleFileRemove}
                    />
                </PreContent>}
                <Textarea
                    passedRef={messageRef}
                    type="textarea"
                    name="message"
                    placeholder={forNote ? "CONVERSATIONS_NOTES_WRITE" : "CONVERSATIONS_WRITE_TEXT"}
                    value={data.message}
                    error={errors.message}
                    onChange={handleChange}
                    isDisabled={sending}
                />
            </Content>

            <Link
                variant="light"
                icon="send"
                onClick={handleMessageSubmit}
                onTouchEnd={handleMessageSubmit}
                isDisabled={sending || !data.message}
                withTabs={canNote}
            />
        </Container>

        <FileInput
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={handleInputChange}
        />
        <EmojiPopup
            open={emojiOpen}
            targetRef={emojiRef}
            direction="top right"
            gap={4}
            onClick={handleEmojiClick}
            onClose={() => setEmojiOpen(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TicketReply.propTypes = {
    elemID   : PropTypes.number,
    canNote  : PropTypes.bool.isRequired,
    onSubmit : PropTypes.func.isRequired,
};

export default TicketReply;
