import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Commons              from "Utils/Commons";

// Components
import ProviderLogView      from "./ProviderLogView";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import FilterList           from "Dashboard/Components/Filter/FilterList";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Provider Log List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProviderLogList(props) {
    const { type } = props;
    const { fetch, load, loadFilter } = useList("providerLog", type);

    const { logID }     = Navigate.useParams();
    const adminNavigate = Commons.useAdminGoto(type);
    const logNavigate   = Commons.useLogGoto(type);

    const data = Store.useState("providerLog");
    const { loading, canEdit, list, total, providers, actions, filters, sort } = data;
    const { hasHospitality } = Store.useState("permission");

    const { deleteElem } = Store.useAction("providerLog");


    // The Current State
    const [ checked, setChecked ] = React.useState([]);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();

    // Variables
    const isAdmin   = type === "ADMIN";
    const showHotel = isAdmin || hasHospitality;


    // Handles the Log change
    React.useEffect(() => {
        if (logID) {
            startAction("VIEW", logID);
        }
    }, [ logID ]);


    // Handles the Action
    const handleAction = (action, elemID) => {
        const { clientID, hotelID, conversationHash } = Utils.getValue(list, "logID", elemID);

        if (action.isClient) {
            adminNavigate(clientID, "SETTINGS", "LOG_PROVIDERS");
        } else if (action.isHotel) {
            adminNavigate(clientID, "SETTINGS", "HOTELS", hotelID);
        } else if (action.isConversation) {
            adminNavigate(clientID, "CONVERSATIONS", conversationHash);
        } else {
            startAction(action, elemID);
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const result = await deleteElem(elemID || checked);
        if (result.success) {
            setChecked([]);
            fetch();
        }
        endAction();
    };

    // Handles the Close
    const handleClose = () => {
        endAction();
        logNavigate("LOG_PROVIDERS");
    };

    // Returns true if the Conversation is hidden
    const hideConversation = (elemID) => {
        const conversationID = Utils.getValue(list, "logID", elemID, "conversationID");
        return !conversationID;
    };

    // Returns true if the Hotel is hidden
    const hideHotel = (elemID) => {
        const hotelID = Utils.getValue(list, "logID", elemID, "hotelID");
        return showHotel && !hotelID;
    };


    // Do the Render
    const canMulti   = canEdit && checked.length > 0;
    const itemAmount = elemID ? 1 : checked.length;

    return <Main>
        <Header message="PROVIDERS_LOG_NAME" icon="provider-log">
            <ActionList onAction={startAction}>
                <ActionItem
                    isHidden={!canMulti}
                    action="DELETE"
                />
            </ActionList>
        </Header>

        <Content>
            <FilterList onFilter={loadFilter} values={filters}>
                <FilterItem
                    name="search"
                    label="GENERAL_SEARCH"
                    icon="search"
                    hasClear
                    shrinkLabel
                />
                <FilterItem
                    type="select"
                    name="provider"
                    label="GENERAL_PLATFORM"
                    options={providers}
                />
                <FilterItem
                    type="select"
                    name="action"
                    label="GENERAL_ACTION"
                    options={actions}
                />
                <FilterItem
                    type="date"
                    name="fromDate"
                    label="GENERAL_FROM_DATE"
                    hasClear
                />
                <FilterItem
                    type="date"
                    name="toDate"
                    label="GENERAL_TO_DATE"
                    hasClear
                />
            </FilterList>

            <Table
                fetch={load}
                sort={sort}
                none="PROVIDERS_LOG_NONE_AVAILABLE"
                isLoading={loading}
                checked={checked}
                setChecked={canEdit ? setChecked : null}
                hasCheckAll
                hasFilter
            >
                <TableHead>
                    <TableHeader field="createdTime"    message="GENERAL_TIME"           maxWidth="180" />
                    <TableHeader field="isError"        message="GENERAL_RESULT"         maxWidth="90"  />
                    <TableHeader field="clientName"     message="CLIENTS_SINGULAR"       isHidden={!isAdmin} />
                    <TableHeader field="action"         message="GENERAL_ACTION"         maxWidth="80"  />
                    <TableHeader field="provider"       message="GENERAL_PLATFORM"       maxWidth="100" />
                    <TableHeader field="modelName"      message="PROVIDERS_LOG_MODEL"    />
                    <TableHeader field="conversationID" message="CONVERSATIONS_SINGULAR" maxWidth="120" align="center" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.logID} elemID={elem.logID}>
                        <TableCell message={elem.createdTimeText}  />
                        <TableCell message={elem.errorText}        className={elem.errorClass} />
                        <TableCell message={elem.clientName}       />
                        <TableCell message={elem.action}           />
                        <TableCell message={elem.provider}         />
                        <TableCell message={elem.modelName}        />
                        <TableCell message={elem.conversationText} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"         message="PROVIDERS_LOG_VIEW_TITLE"   navigate />
                    <TableAction action="CLIENT"       message="CLIENTS_VIEW_TITLE"         isHidden={!isAdmin} />
                    <TableAction action="CONVERSATION" message="CONVERSATIONS_VIEW_TITLE"   hide={hideConversation} />
                    <TableAction action="HOTEL"        message="HOTELS_VIEW_TITLE"          hide={hideHotel} />
                    <TableAction action="DELETE"       message="PROVIDERS_LOG_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <ProviderLogView
            open={action.isVCE}
            isAdmin={isAdmin}
            elemID={elemID}
            onClose={handleClose}
        />
        <DeleteDialog
            open={action.isDelete}
            title="PROVIDERS_LOG_DELETE_TITLE"
            message={NLS.pluralize("PROVIDERS_LOG_DELETE_TEXT", itemAmount)}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProviderLogList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ProviderLogList;
