import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Commons              from "Utils/Commons";

// Components
import ClientEdit           from "Components/App/Admin/Client/ClientEdit";
import TagList              from "Components/App/Config/Tag/TagList";
import TongueList           from "Components/App/Config/Tongue/TongueList";
import MsgTemplateList      from "Components/App/Config/MsgTemplate/MsgTemplateList";
import MsgTemplatePage      from "Components/App/Config/MsgTemplate/MsgTemplatePage";
import QuestionnaireList    from "Components/App/Config/Questionnaire/QuestionnaireList";
import QuestionnairePage    from "Components/App/Config/Questionnaire/QuestionnairePage";
import ScheduleList         from "Components/App/Config/Schedule/ScheduleList";
import SchedulePage         from "Components/App/Config/Schedule/SchedulePage";
import CustomFieldList      from "Components/App/Config/CustomField/CustomFieldList";
import HotelList            from "Components/App/Config/Hotel/HotelList";
import HotelPage            from "Components/App/Config/Hotel/HotelPage";
import ChannelList          from "Components/App/Config/Channel/ChannelList";
import ChannelPage          from "Components/App/Config/Channel/ChannelPage";
import PhoneNumberList      from "Components/App/Config/PhoneNumber/PhoneNumberList";
import WabaTemplateList     from "Components/App/Config/WabaTemplate/WabaTemplateList";
import WabaTemplatePage     from "Components/App/Config/WabaTemplate/WabaTemplatePage";
import AssistantList        from "Components/App/Config/Assistant/AssistantList";
import AssistantPage        from "Components/App/Config/Assistant/AssistantPage";
import WidgetList           from "Components/App/Config/Widget/WidgetList";
import WidgetPage           from "Components/App/Config/Widget/WidgetPage";
import FlowList             from "Components/App/Config/Flow/FlowList";
import FlowPage             from "Components/App/Config/Flow/FlowPage";
import IntegrationList      from "Components/App/Config/Integration/IntegrationList";
import IntegrationPage      from "Components/App/Config/Integration/IntegrationPage";
import ConnectorList        from "Components/App/Config/Connector/ConnectorList";
import ConnectorPage        from "Components/App/Config/Connector/ConnectorPage";
import MediaPage            from "Components/App/Setup/Media/MediaPage";
import ProviderLogList      from "Components/App/Log/ProviderLog/ProviderLogList";
import FetchLogList         from "Components/App/Log/FetchLog/FetchLogList";
import AssistantRunLogList  from "Components/App/Log/AssistantRunLog/AssistantRunLogList";
import ActionLogList        from "Components/App/Log/ActionLog/ActionLogList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import UserRoute            from "Dashboard/Components/Route/UserRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Config Container
 * @returns {React.ReactElement}
 */
function ConfigContainer() {
    const { clientID } = Navigate.useParams();

    const partnerID = Commons.usePartnerID();

    const { closeMenu } = Store.useAction("core");
    const { isAnyAdmin, isPartner, isOwner } = Store.useState("auth");

    const {
        hasPhoneNumbers, hasHotel,
        hasAssistantAI, hasIntegrations, hasConnectors,
    } = Store.useState("permission");

    const { elem : client } = Store.useState("client");
    const { fetchElem } = Store.useAction("client");

    // The Current State
    const [ action, setAction ] = Action.useAction();


    // Load the Data
    React.useEffect(() => {
        if (clientID && clientID !== client.id) {
            fetchElem(clientID);
        }
    }, [ clientID ]);

    // Handles the Action
    const handleAction = (action) => {
        if (action.isCE) {
            closeMenu();
            setAction(action);
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        fetchElem(clientID);
        setAction();
    };


    // Variables
    const showAll     = isAnyAdmin || isPartner || isOwner;
    const showFetches = showAll && (hasIntegrations || hasConnectors);
    const isParent    = isAnyAdmin || isPartner;
    const href        = Navigate.useFrom();
    const type        = isParent ? "CLIENT" : "USER";


    // Do the Render
    return <>
        <SecondaryNav
            message={isParent ? client.name : ""}
            fallback="GENERAL_SETTINGS"
            href={isParent ? href : "/"}
            onAction={handleAction}
            canEdit={isParent}
        >
            <NavigationList>
                <NavigationItem
                    message="TAGS_NAME"
                    url="TAGS"
                    icon="tag"
                />
                <NavigationItem
                    message="LANGUAGES_NAME"
                    url="LANGUAGES"
                    icon="language"
                />
                <NavigationItem
                    message="MSG_TEMPLATES_NAME"
                    url="MSG_TEMPLATES"
                    icon="template"
                />
                <NavigationItem
                    message="QUESTIONNAIRES_NAME"
                    url="QUESTIONNAIRES"
                    icon="questionnaire"
                />
                <NavigationItem
                    message="SCHEDULES_NAME"
                    url="SCHEDULES"
                    icon="schedule"
                />
                <NavigationItem
                    message="CUSTOM_FIELDS_NAME"
                    url="CUSTOM_FIELDS"
                    icon="field"
                />
                <NavigationItem
                    message="MEDIA_NAME"
                    url="MEDIA"
                    icon="media"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    isHidden={!showAll || !hasHotel}
                    message="HOTELS_NAME"
                    url="HOTELS"
                    icon="hotel"
                />
                <NavigationItem
                    isHidden={!showAll}
                    message="CHANNELS_NAME"
                    url="CHANNELS"
                    icon="channel"
                />
                <NavigationItem
                    isHidden={!showAll || !hasPhoneNumbers}
                    message="PHONE_NUMBERS_NAME"
                    url="PHONE_NUMBERS"
                    icon="phone-number"
                />
                <NavigationItem
                    isHidden={!showAll}
                    message="WABA_TEMPLATES_NAME"
                    url="WABA_TEMPLATES"
                    icon="whatsapp"
                />
                <NavigationItem
                    isHidden={!showAll || !hasAssistantAI}
                    message="ASSISTANTS_NAME"
                    url="ASSISTANTS"
                    icon="assistant"
                />
                <NavigationItem
                    message="WIDGETS_NAME"
                    url="WIDGETS"
                    icon="widget"
                />
                <NavigationItem
                    message="FLOWS_NAME"
                    url="FLOWS"
                    icon="flow"
                />
                <NavigationItem
                    isHidden={!hasIntegrations}
                    message="INTEGRATIONS_NAME"
                    url="INTEGRATIONS"
                    icon="integration"
                />
                <NavigationItem
                    isHidden={!hasConnectors}
                    message="CONNECTORS_NAME"
                    url="CONNECTORS"
                    icon="connector"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    isHidden={!showAll}
                    message="PROVIDERS_LOG_NAME"
                    url="LOG_PROVIDERS"
                    icon="provider-log"
                />
                <NavigationItem
                    isHidden={!showFetches}
                    message="FETCHES_LOG_NAME"
                    url="LOG_FETCHES"
                    icon="fetch"
                />
                <NavigationItem
                    isHidden={!showAll || !hasAssistantAI}
                    message="ASSISTANTS_RUNS_NAME"
                    url="LOG_ASSISTANTS"
                    icon="assistant-log"
                />
                <NavigationItem
                    isHidden={!showAll}
                    message="ACTIONS_LOG_NAME"
                    url="LOG_ACTIONS"
                    icon="action-log"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type={type}>
            <UserRoute
                url="TAGS"
                component={TagList}
            />
            <UserRoute
                url="LANGUAGES"
                component={TongueList}
            />


            <UserRoute
                url="MSG_TEMPLATES"
                component={MsgTemplateList}
            />
            <UserRoute
                url="MSG_TEMPLATE"
                component={MsgTemplatePage}
            />
            <UserRoute
                url="MSG_TEMPLATE_ADD"
                component={MsgTemplatePage}
            />
            <UserRoute
                url="MSG_TEMPLATE_COPY"
                component={MsgTemplatePage}
            />


            <UserRoute
                url="QUESTIONNAIRES"
                component={QuestionnaireList}
            />
            <UserRoute
                url="QUESTIONNAIRE"
                component={QuestionnairePage}
            />
            <UserRoute
                url="QUESTIONNAIRE_ADD"
                component={QuestionnairePage}
            />
            <UserRoute
                url="QUESTIONNAIRE_COPY"
                component={QuestionnairePage}
            />


            <UserRoute
                url="SCHEDULES"
                component={ScheduleList}
            />
            <UserRoute
                url="SCHEDULE"
                component={SchedulePage}
            />
            <UserRoute
                url="SCHEDULE_ADD"
                component={SchedulePage}
            />
            <UserRoute
                url="SCHEDULE_COPY"
                component={SchedulePage}
            />


            <UserRoute
                url="CUSTOM_FIELDS"
                component={CustomFieldList}
            />
            <UserRoute
                url="MEDIA"
                component={MediaPage}
            />


            <UserRoute
                isHidden={!showAll || !hasHotel}
                url="HOTELS"
                component={HotelList}
            />
            <UserRoute
                isHidden={!showAll || !hasHotel}
                url="HOTEL"
                component={HotelPage}
            />
            <UserRoute
                isHidden={!showAll || !hasHotel}
                url="HOTELS_ADD"
                component={HotelPage}
            />


            <UserRoute
                isHidden={!showAll}
                url="CHANNELS"
                component={ChannelList}
            />
            <UserRoute
                isHidden={!showAll}
                url="CHANNELS_ADD"
                component={ChannelPage}
            />
            <UserRoute
                isHidden={!showAll || !hasPhoneNumbers}
                url="PHONE_NUMBERS"
                component={PhoneNumberList}
            />


            <UserRoute
                isHidden={!showAll}
                url="WABA_TEMPLATES"
                component={WabaTemplateList}
            />
            <UserRoute
                isHidden={!showAll}
                url="WABA_TEMPLATE"
                component={WabaTemplatePage}
            />
            <UserRoute
                isHidden={!showAll}
                url="WABA_TEMPLATE_ADD"
                component={WabaTemplatePage}
            />
            <UserRoute
                isHidden={!showAll}
                url="WABA_TEMPLATE_COPY"
                component={WabaTemplatePage}
            />


            <UserRoute
                isHidden={!showAll || !hasAssistantAI}
                url="ASSISTANTS"
                component={AssistantList}
            />
            <UserRoute
                isHidden={!showAll || !hasAssistantAI}
                url="ASSISTANT"
                component={AssistantPage}
            />
            <UserRoute
                isHidden={!showAll || !hasAssistantAI}
                url="ASSISTANT_ADD"
                component={AssistantPage}
            />


            <UserRoute
                url="WIDGETS"
                component={WidgetList}
            />
            <UserRoute
                url="WIDGET"
                component={WidgetPage}
            />
            <UserRoute
                url="WIDGET_TAB"
                component={WidgetPage}
            />


            <UserRoute
                url="FLOWS"
                component={FlowList}
            />
            <UserRoute
                url="FLOW"
                component={FlowPage}
            />
            <UserRoute
                url="FLOW_TAB"
                component={FlowPage}
            />


            <UserRoute
                isHidden={!hasIntegrations}
                url="INTEGRATIONS"
                component={IntegrationList}
            />
            <UserRoute
                isHidden={!hasIntegrations}
                url="INTEGRATION"
                component={IntegrationPage}
            />
            <UserRoute
                isHidden={!hasIntegrations}
                url="INTEGRATION_TAB"
                component={IntegrationPage}
            />


            <UserRoute
                isHidden={!hasConnectors}
                url="CONNECTORS"
                component={ConnectorList}
            />
            <UserRoute
                isHidden={!hasConnectors}
                url="CONNECTOR"
                component={ConnectorPage}
            />
            <UserRoute
                isHidden={!hasConnectors}
                url="CONNECTOR_ADD"
                component={ConnectorPage}
            />


            <UserRoute
                isHidden={!showAll}
                url="LOG_PROVIDERS"
                component={ProviderLogList}
            />
            <UserRoute
                isHidden={!showAll}
                url="LOG_PROVIDER"
                component={ProviderLogList}
            />
            <UserRoute
                isHidden={!showFetches}
                url="LOG_FETCHES"
                component={FetchLogList}
            />
            <UserRoute
                isHidden={!showFetches}
                url="LOG_FETCH"
                component={FetchLogList}
            />
            <UserRoute
                isHidden={!showAll || !hasAssistantAI}
                url="LOG_ASSISTANTS"
                component={AssistantRunLogList}
            />
            <UserRoute
                isHidden={!showAll || !hasAssistantAI}
                url="LOG_ASSISTANT"
                component={AssistantRunLogList}
            />
            <UserRoute
                isHidden={!showAll}
                url="LOG_ACTIONS"
                component={ActionLogList}
            />
        </Router>

        <ClientEdit
            open={action.isEdit}
            elemID={clientID}
            partnerID={partnerID}
            onSubmit={handleEdit}
            onClose={setAction}
        />
    </>;
}

export default ConfigContainer;
