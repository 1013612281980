import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ReplyActions         from "./ReplyActions";
import ReplyTemplate        from "./ReplyTemplate";
import ReplyMessage         from "./ReplyMessage";
import ReplyMenus           from "./ReplyMenus";
import ReplyAssistant       from "./ReplyAssistant";

// Dashboard
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import DragDrop             from "Dashboard/Components/Media/DragDrop";
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.footer`
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 8px;
    background-color: var(--lightest-gray);
    border-top: 1px solid var(--border-color-light);
`;

const Header = Styled.header`
    display: flex;
    justify-content: space-between;
`;

const Tabs = Styled(TabList)`
    margin-bottom: 4px;
`;

const ReplyError = Styled(Html)`
    min-height: 43px;
    padding-left: 8px;
`;



/**
 * The Reply Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReplyContainer(props) {
    const {
        clientID, conversationHash, replyToID,
        replyMessage, onReplyRemove, onSubmit,
    } = props;

    const { elem, msgTemplates } = Store.useState("conversation");
    const { setWriting } = Store.useAction("conversation");
    const { showResult } = Store.useAction("core");


    // The References
    const inputRef           = React.useRef(null);
    const msgTemplateMenuRef = React.useRef(null);
    const formatMenuRef      = React.useRef(null);
    const emojiMenuRef       = React.useRef(null);
    const fileMenuRef        = React.useRef(null);
    const fileInputRef       = React.useRef(null);
    const variableMenuRef    = React.useRef(null);
    const channelMenuRef     = React.useRef(null);
    const timerRef           = React.useRef(null);

    // The Current State
    const [ tab,    setTab    ] = React.useState("message");
    const [ menu,   setMenu   ] = React.useState("");
    const [ dialog, setDialog ] = React.useState("");
    const [ text,   setText   ] = React.useState("");
    const [ files,  setFiles  ] = React.useState([]);
    const [ amount, setAmount ] = React.useState(0);

    // Variables
    const isMessage      = tab === "message";
    const isNote         = tab === "note";
    const forNote        = !elem.canSend || tab === "note";
    const showReplyError = isMessage && !elem.canReply && elem.replyDays > 0;
    const showTemplate   = isMessage && elem.requiresTemplate;
    const showInput      = (isMessage && elem.canSend && elem.canReply && !elem.requiresTemplate) || isNote;
    const replyTimeText  = elem.replyDays === 1 ? NLS.get("CONVERSATIONS_REPLY_ERROR_HOURS") : NLS.format("CONVERSATIONS_REPLY_ERROR_DAYS", elem.replyDays);


    // Set the Tab and Writing
    React.useEffect(() => {
        if (elem.id) {
            setTab(!elem.canSend ? "note" : "message");
            setText("");
            setFiles([]);
            window.addEventListener("beforeunload", () => setWriting(elem.id, 0));
        }

        return () => {
            Utils.clearTimeout(timerRef);
            if (elem.id) {
                window.removeEventListener("beforeunload", () => setWriting(elem.id, 0));
                setWriting(elem.id, 0);
            }
        };
    }, [ elem.id, elem.canSend ]);

    // Focus the Input
    React.useEffect(() => {
        if (replyToID && showInput) {
            setTab("message");
            inputRef.current.focus();
            handleFileRemove();
        }
    }, [ replyToID ]);


    // Handles the File Add
    const handleFileAdd = (file, name, setAll) => {
        if (forNote || setAll) {
            setFiles([{ file, name }]);
            setAmount(1);
        } else if (!files.filter((elem) => elem.name === name).length) {
            files.push({ file, name });
            setAmount(amount + 1);
            setFiles(files);
        }
        fileInputRef.current.value = "";
    };

    // Handles the File Multi Add
    const handleFileMultiAdd = (newFiles) => {
        if (forNote) {
            setFiles([ newFiles[0] ]);
            setAmount(1);
        } else {
            for (const newFile of newFiles) {
                if (!files.filter((elem) => elem.name === newFile.name).length) {
                    files.push({
                        file : newFile,
                        name : newFile.name,
                    });
                }
            }
            setFiles(files);
            setAmount(files.length);
        }
        fileInputRef.current.value = "";
    };

    // Handles the File Remove
    const handleFileRemove = (index, removeAll) => {
        if (removeAll) {
            setFiles([]);
            setAmount(0);
        } else {
            files.splice(index, 1);
            setFiles(files);
            setAmount(amount - 1);
        }
        fileInputRef.current.value = "";
    };

    // Handles a File Error
    const handleFileError = (files) => {
        showResult("error", "MEDIA_ERROR_UPLOAD_SIZE" + (files > 1 ? "S" : ""));
    };

    // Handles the Generate
    const handleGenerate = (message) => {
        if (message) {
            setText(message);
        }
        setDialog("");
    };


    // Returns a list of Message Templates
    const msgTemplateList = React.useMemo(() => {
        const templates = msgTemplates[elem.tongueID || 0] || [];
        const result    = [];
        for (const template of templates) {
            let add = 0;
            if (!elem.teamID || !template.teams.length || template.teams.includes(elem.teamID)) {
                add += 1;
            }
            if (!elem.hotelID || !template.hotels.length || template.hotels.includes(elem.hotelID)) {
                add += 1;
            }
            if (add === 2) {
                result.push(template);
            }
        }
        return result;
    }, [ msgTemplates, elem.teamID, elem.hotelID ]);



    // Do the Render
    return <>
        <Container>
            <Header>
                <Tabs selected={tab} onClick={setTab}>
                    <TabItem
                        isHidden={!elem.canSend}
                        message="GENERAL_MESSAGE"
                        value="message"
                    />
                    <TabItem
                        message="CONVERSATIONS_NOTES_SINGULAR"
                        value="note"
                    />
                </Tabs>

                {showInput && <ReplyActions
                    msgTemplateMenuRef={msgTemplateMenuRef}
                    formatMenuRef={formatMenuRef}
                    emojiMenuRef={emojiMenuRef}
                    fileMenuRef={fileMenuRef}
                    variableMenuRef={variableMenuRef}
                    channelMenuRef={channelMenuRef}
                    msgTemplates={msgTemplateList}
                    onMenu={setMenu}
                    onDialog={setDialog}
                />}
            </Header>

            {showReplyError && <ReplyError
                message={NLS.format("CONVERSATIONS_REPLY_ERROR_MESSAGE", replyTimeText)}
            />}
            <ReplyTemplate
                isHidden={!showTemplate}
            />
            <ReplyMessage
                isHidden={!showInput}
                inputRef={inputRef}
                conversationHash={conversationHash}
                forNote={forNote}
                replyToID={replyToID}
                replyMessage={replyMessage}
                onReplyRemove={onReplyRemove}
                files={files}
                onFileMultiAdd={handleFileMultiAdd}
                onFileRemove={handleFileRemove}
                onFileError={handleFileError}
                text={text}
                setText={setText}
                onSubmit={onSubmit}
            />
        </Container>

        {showInput && <ReplyMenus
            inputRef={inputRef}
            msgTemplateMenuRef={msgTemplateMenuRef}
            formatMenuRef={formatMenuRef}
            emojiMenuRef={emojiMenuRef}
            fileMenuRef={fileMenuRef}
            fileInputRef={fileInputRef}
            variableMenuRef={variableMenuRef}
            channelMenuRef={channelMenuRef}
            msgTemplates={msgTemplateList}
            text={text}
            onText={setText}
            menu={menu}
            onMenu={setMenu}
            forNote={forNote}
            onFileAdd={handleFileAdd}
            onFileMultiAdd={handleFileMultiAdd}
            onFileError={handleFileError}
            onReplyRemove={onReplyRemove}
        />}

        <ReplyAssistant
            open={dialog === "assistant"}
            clientID={clientID}
            conversationID={elem.conversationID}
            forNote={forNote}
            onSubmit={handleGenerate}
            onClose={() => setDialog("")}
        />
        <DragDrop
            isHidden={!showInput}
            onDrop={handleFileMultiAdd}
            onError={handleFileError}
            maxSize={process.env.REACT_APP_MAX_SIZE}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReplyContainer.propTypes = {
    clientID         : PropTypes.number.isRequired,
    conversationHash : PropTypes.string.isRequired,
    replyToID        : PropTypes.number.isRequired,
    replyMessage     : PropTypes.string.isRequired,
    onReplyRemove    : PropTypes.func.isRequired,
    onSubmit         : PropTypes.func.isRequired,
};

export default ReplyContainer;
