import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Query Log View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function QueryLogView(props) {
    const { open, elemID, onClose } = props;

    const { fetchElem, resolveElem, deleteElem } = Store.useAction("queryLog");


    // The Current State
    const [ update,     setUpdate     ] = React.useState(false);
    const [ showDelete, setShowDelete ] = React.useState(false);

    // Load the Data
    const { loading, elem } = useDialog("queryLog", open, elemID);


    // Handles the Mark as Resolved
    const handleResolve = async () => {
        const response = await resolveElem(elemID);
        if (response.success) {
            fetchElem(elemID);
            setUpdate(true);
        }
    };

    // Handles the Delete
    const handleDelete = async () => {
        const response = await deleteElem(elemID);
        if (response.success) {
            setShowDelete(false);
            onClose(true);
        }
    };

    // Handles the Dialog Close
    const handleClose = () => {
        onClose(update);
    };

    // Formats the Expression
    const expression = React.useMemo(() => {
        if (!elem.expression) {
            return "";
        }

        const jumps = [ "FROM", "LEFT JOIN", "UNION", "WHERE", "GROUP BY", "ORDER BY", "LIMIT" ];
        const words = [
            "SELECT", "INSERT", "UPDATE", "INTO", "FROM", "UNION", "JOIN", "LEFT", "SET",
            "WHERE", "GROUP BY", "ORDER BY", "LIMIT", "DESC", "ASC", "SIGNED", "CAST",
            "CASE WHEN", "IF", " AS ", " ON ", " IN ", " AND ", "THEN", "ELSE", "END",
        ];

        let result = elem.expression;
        jumps.forEach((word) => {
            result = result.replace(new RegExp(`(${word})`, "g"), "<br>$1");
        });
        words.forEach((word) => {
            result = result.replace(new RegExp(`(${word})`, "g"), "<b>$1</b>");
        });
        return result;
    }, [ elem.expression ]);


    // Do the Render
    return <>
        <ViewDialog
            open={open}
            icon="query-log"
            title="QUERIES_LOG_VIEW_TITLE"
            secondary={!elem.isResolved ? "QUERIES_LOG_RESOLVE_TITLE" : ""}
            onSecondary={handleResolve}
            tertiary="GENERAL_DELETE"
            onTertiary={() => setShowDelete(true)}
            onClose={handleClose}
            isLoading={loading}
            withSpacing
        >
            <Columns>
                <ViewField
                    label="GENERAL_TIME"
                    value={elem.updatedTimeText}
                />
                <ViewField
                    label="GENERAL_STATUS"
                    viewClass={elem.resolvedClass}
                    value={elem.resolvedText}
                />
                <ViewField
                    label="GENERAL_ENVIRONMENT"
                    value={elem.environment}
                    showEmpty
                />
                <ViewField
                    label="GENERAL_AMOUNT"
                    value={elem.amount}
                />
                <ViewField
                    label="GENERAL_DURATION"
                    value={elem.elapsedTimeText}
                />
                <ViewField
                    label="GENERAL_TOTAL_DURATION"
                    value={elem.totalTimeText}
                />
            </Columns>
            <ViewField
                label="QUERIES_LOG_EXPRESSION"
                value={expression}
                hasCopy
            />
        </ViewDialog>

        <DeleteDialog
            open={showDelete}
            title="QUERIES_LOG_DELETE_TITLE"
            message={NLS.pluralize("QUERIES_LOG_DELETE_TEXT", 1)}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
QueryLogView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elemID  : PropTypes.number,
};

export default QueryLogView;
