import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import WhatsAppInstall      from "../Channels/WhatsAppInstall";
import InstagramInstall     from "../Channels/InstagramInstall";
import MessengerInstall     from "../Channels/MessengerInstall";
import TwilioInstall        from "../Channels/TwilioInstall";
import GmailInstall         from "../Channels/GmailInstall";
import OutlookInstall       from "../Channels/OutlookInstall";
import TelegramInstall      from "../Channels/TelegramInstall";



// Styles
const Container = Styled.div`
    max-width: var(--provider-width);
    margin: 0 auto;
    line-height: 1.5;

    & > section {
        margin: 16px 0;
    }
    .user-link {
        display: inline-block;
        margin-top: 8px;
    }
`;



/**
 * The Channel Install
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChannelInstall(props) {
    const { data, provider, onInstall } = props;


    // Do the Render
    return <Container>
        <WhatsAppInstall
            isHidden={data.providerType !== "WhatsApp"}
            provider={provider}
            onInstall={onInstall}
        />
        <InstagramInstall
            isHidden={data.providerType !== "Instagram"}
            provider={provider}
            onInstall={onInstall}
        />
        <MessengerInstall
            isHidden={data.providerType !== "Messenger"}
            provider={provider}
            onInstall={onInstall}
        />
        <TwilioInstall
            isHidden={data.providerType !== "Twilio"}
            provider={provider}
            onInstall={onInstall}
        />
        <GmailInstall
            isHidden={data.providerType !== "Gmail"}
            provider={provider}
            onInstall={onInstall}
        />
        <OutlookInstall
            isHidden={data.providerType !== "Outlook"}
            provider={provider}
            onInstall={onInstall}
        />
        <TelegramInstall
            isHidden={data.providerType !== "Telegram"}
            provider={provider}
            onInstall={onInstall}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChannelInstall.propTypes = {
    data      : PropTypes.object.isRequired,
    provider  : PropTypes.object.isRequired,
    onInstall : PropTypes.func.isRequired,
};

export default ChannelInstall;
