const actions = {

    // Auth
    Session : {
        name    : "Sesión",
        actions : {
            Login      : "Inicio de sesión",
            LoginAs    : "Inicio como usuario",
            Logout     : "Cierre de sesión",
            LogoutAs   : "Cierre como usuario",
            Disconnect : "Desconectar dispositivo",
            Recover    : "Recupero de contraseña",
            ResetPass  : "Contraseña modificada",
            ChangePass : "Contraseña cambiada",
            Join       : "Unido a la organización",
        },
    },
    Profile : {
        name    : "Perfil",
        actions : {
            Edit   : "Perfil editado",
            Upload : "Foto subida",
            Delete : "Perfil eliminado",
        },
    },
    Organization : {
        name    : "Organización",
        actions : {
            Accept : "Organización aceptada",
            Access : "Organización accedida",
        },
    },



    // Admin
    Client : {
        name    : "Cliente",
        actions : {
            Export  : "Clientes exportados",
            Create  : "Cliente creado",
            Edit    : "Cliente editado",
            Approve : "Cliente aprobado",
            Delete  : "Cliente eliminado",
        },
    },
    Partner : {
        name    : "Partner",
        actions : {
            Export : "Partners exportados",
            Create : "Partner creado",
            Edit   : "Partner editado",
            Delete : "Partner eliminado",
        },
    },
    Subscription : {
        name    : "Suscripción",
        actions : {
            Export : "Suscripciones exportadas",
            Delete : "Suscripción eliminada",
        },
    },



    // Client
    Conversation : {
        name    : "Conversación",
        actions : {
            Create     : "Conversación creada",
            Edit       : "Conversación editada",
            Template   : "Plantilla de WhatsApp enviada",
            Send       : "Mensaje de conversación enviado",
            Forward    : "Mensaje de conversación reenviado",
            Accept     : "Conversación aceptada",
            Return     : "Conversación devuelta",
            Progress   : "Conversation en curso",
            Followup   : "Conversation en seguimiento",
            Resolve    : "Conversación resuelta",
            Close      : "Conversación cerrada",
            Open       : "Conversación abierta",
            Unread     : "Conversación marcada como no leída",
            User       : "Usuario asignado a conversación",
            Team       : "Equipo asignado a conversación",
            Tag        : "Etiquetas actualizadas en conversación",
            Tongue     : "Idioma asignado a conversación",
            Hotel      : "Hotel asignado a conversación",
            Flow       : "Flujo asignado a conversación",
            Generate   : "Text de conversación generado",
            Summarize  : "Conversación resumida",
            Delete     : "Conversación eliminada",

            NoteCreate : "Nota de conversación creada",
            NoteEdit   : "Nota de conversación editada",
            NoteDelete : "Nota de conversación eliminada",
        },
    },
    Store : {
        name    : "Tienda",
        actions : {
            Create         : "Tienda creada",
            Edit           : "Tienda editada",
            Activate       : "Tienda activada",
            Publish        : "Tienda publicada",
            Clear          : "Tienda borrada",
            Pause          : "Tienda pausada",
            Delete         : "Tienda eliminada",

            OrderEdit      : "Pedido de tienda editado",

            ProductCreate  : "Producto de tienda creado",
            ProductEdit    : "Producto de tienda editado",
            ProductDelete  : "Producto de tienda eliminado",

            CategoryCreate : "Categoría de tienda creada",
            CategoryEdit   : "Categoría de tienda editada",
            CategoryDelete : "Categoría de tienda eliminada",

            BannerCreate   : "Banner de tienda creado",
            BannerEdit     : "Banner de tienda editado",
            BannerDelete   : "Banner de tienda eliminado",

            EditOption     : "Opción de tienda editada",
        },
    },
    Contact : {
        name    : "Contacto",
        actions : {
            Create  : "Contacto creado",
            Edit    : "Contacto editado",
            Export  : "Contactos exportados",
            Merge   : "Contactos combinados",
            Import  : "Contactos importados",
            Block   : "Contacto bloqueado",
            Unblock : "Contacto desbloqueado",
            Delete  : "Contacto eliminado",
        },
    },
    Hospitality : {
        name    : "Hospitality",
        actions : {
            Create : "Hospitality creado",
            Edit   : "Hospitality editado",
            Delete : "Hospitality eliminado",
            Export : "Hospitalities exportados",
            Import : "Hospitalities importados",
        },
    },
    Account : {
        name    : "Cuenta",
        actions : {
            Create : "Cuenta creada",
            Edit   : "Cuenta editada",
            Delete : "Cuenta eliminada",
            Export : "Cuentas exportadas",
            Import : "Cuentas importadas",
        },
    },
    Campaign : {
        name    : "Campaña",
        actions : {
            Create          : "Campaña creada",
            EditOption      : "Campaña editada",
            Activate        : "Campaña activada",
            Pause           : "Campaña pausada",
            Complete        : "Campaña completada",
            Publish         : "Campaña publicada",
            Clear           : "Campaña borrada",
            Delete          : "Campaña eliminada",

            TriggerCreate   : "Trigger de campaña creado",
            TriggerEdit     : "Trigger de campaña editado",
            TriggerDelete   : "Trigger de campaña eliminado",

            ConditionCreate : "Condición de campaña creado",
            ConditionEdit   : "Condición de campaña editado",
            ConditionDelete : "Condición de campaña eliminado",

            GroupCreate     : "Grupo de condiciones creado",
            GroupEdit       : "Grupo de condiciones editado",
            GroupDelete     : "Grupo de condiciones eliminado",

            ExecutionDelete : "Envío de campaña eliminado",
        },
    },
    User : {
        name    : "Usuario",
        actions : {
            Export       : "Usuarios exportados",
            Create       : "Usuario creado",
            Invite       : "Usuario invitado",
            ResendInvite : "Invitación enviada",
            Edit         : "Usuario editado",
            Delete       : "Usuario eliminado",
        },
    },
    Team : {
        name    : "Equipo",
        actions : {
            Create : "Equipo creado",
            Edit   : "Equipo editado",
            User   : "Equipos del Usuario editados",
            Delete : "Equipo eliminado",
        },
    },



    // Config
    Tag : {
        name    : "Etiqueta",
        actions : {
            Create : "Etiqueta creada",
            Edit   : "Etiqueta editada",
            Delete : "Etiqueta eliminada",
        },
    },
    Tongue : {
        name    : "Lenguaje",
        actions : {
            Create : "Lenguaje creado",
            Edit   : "Lenguaje editado",
            Delete : "Lenguaje eliminado",
        },
    },
    MsgTemplate : {
        name    : "Plantilla de mensaje",
        actions : {
            Create : "Plantilla de mensaje creada",
            Edit   : "Plantilla de mensaje editada",
            Delete : "Plantilla de mensaje eliminada",
        },
    },
    Questionnaire : {
        name    : "Preguntas frecuentes",
        actions : {
            Create : "Preguntas frecuentes creadas",
            Edit   : "Preguntas frecuentes editadas",
            Delete : "Preguntas frecuentes eliminadas",
        },
    },
    Schedule : {
        name    : "Horario de atención",
        actions : {
            Create : "Horario de atención creado",
            Edit   : "Horario de atención editado",
            Delete : "Horario de atención eliminado",
        },
    },
    CustomField : {
        name    : "Campo personalizado",
        actions : {
            Create : "Campo personalizado creado",
            Edit   : "Campo personalizado editado",
            Delete : "Campo personalizado eliminado",
        },
    },

    Hotel : {
        name    : "Hotel",
        actions : {
            Add    : "Hotel agregado",
            Edit   : "Hotel editado",
            Delete : "Hotel eliminado",
        },
    },
    Channel : {
        name    : "Canal",
        actions : {
            Add         : "Canal configurado",
            Edit        : "Canal editado",
            EditProfile : "Perfil de canal editado",
            Delete      : "Canal eliminado",
        },
    },
    PhoneNumber : {
        name    : "Número de teléfono",
        actions : {
            Add    : "Número de teléfono agregado",
            Import : "Número de teléfono importado",
            Edit   : "Número de teléfono editado",
            Delete : "Número de teléfono eliminado",
        },
    },
    WabaTemplate : {
        name    : "Plantilla de WhatsApp",
        actions : {
            Create : "Plantilla de WhatsApp creada",
            Edit   : "Plantilla de WhatsApp editada",
            Import : "Plantilla de WhatsApp importada",
            Delete : "Plantilla de WhatsApp eliminada",
        },
    },
    Assistant : {
        name    : "Asistente IA",
        actions : {
            Create : "Asistente IA creado",
            Edit   : "Asistente IA editado",
            Delete : "Asistente IA eliminado",
        },
    },
    AssistantModel : {
        name    : "Modelo de asistente IA",
        actions : {
            Create : "Modelo de asistente IA creado",
            Edit   : "Modelo de asistente IA editado",
            Delete : "Modelo de asistente IA eliminado",
        },
    },
    Widget : {
        name    : "Widget omnicanal",
        actions : {
            Create         : "Widget omnicanal creado",
            Copy           : "Widget omnicanal copiado",
            Edit           : "Widget omnicanal editado",
            Publish        : "Widget omnicanal publicado",
            Clear          : "Widget omnicanal borrado",
            Delete         : "Widget omnicanal eliminado",

            PlatformCreate : "Plataforma de widget creada",
            PlatformEdit   : "Plataforma de widget editada",
            PlatformSort   : "Plataformas de widget ordenadas",
            PlatformDelete : "Plataforma de widget eliminada",
        },
    },
    Flow : {
        name    : "Flujo",
        actions : {
            Create            : "Flujo creado",
            Copy              : "Flujo copiado",
            Edit              : "Flujo editado",
            Publish           : "Flujo publicado",
            Clear             : "Flujo borrado",
            Delete            : "Flujo eliminado",

            NodeCreate        : "Nodo de flujo creado",
            NodeEdit          : "Nodo de flujo editado",
            NodeDrag          : "Nodo de flujo arrastrado",
            NodeMove          : "Nodo de flujo copiado",
            NodeCopy          : "Nodo de flujo movido",
            NodeDelete        : "Nodo de flujo eliminado",

            EdgeCreate        : "Enlace de flujo creado",
            EdgeEdit          : "Enlace de flujo editado",
            EdgeDelete        : "Enlace de flujo eliminado",

            SimulationStart   : "Simulación de flujo iniciada",
            SimulationRestart : "Simulación de flujo reiniciada",
            SimulationEnd     : "Simulación de flujo terminada",
        },
    },
    Integration : {
        name    : "Integración",
        actions : {
            Create       : "Integración creada",
            Copy         : "Integración copiada",
            Edit         : "Integración editada",
            Activate     : "Integración activada",
            Publish      : "Integración publicada",
            Clear        : "Integración borrada",
            Pause        : "Integración pausada",
            Delete       : "Integración eliminada",

            ActionCreate : "Acción de integración creada",
            ActionCopy   : "Acción de integración copiada",
            ActionEdit   : "Acción de integración editada",
            ActionMove   : "Acción de integración movida",
            ActionDelete : "Acción de integración eliminada",
        },
    },
    Connector : {
        name    : "Conector",
        actions : {
            Create : "Conector creado",
            Edit   : "Conector editado",
            Delete : "Conector eliminado",
        },
    },
    ConnectorType : {
        name    : "Tipo de conector",
        actions : {
            Edit : "Tipo de conector editado",
        },
    },



    // Support
    Ticket : {
        name    : "Ticket",
        actions : {
            Create        : "Ticket creado",
            Edit          : "Ticket editado",
            Delete        : "Ticket eliminado",
            Assign        : "Ticket asignado",
            Open          : "Ticket abierto",
            Wait          : "Ticket en espera",
            Close         : "Ticket cerrado",
            Mute          : "Ticket silenciado",
            Unmute        : "Ticket activado",

            MessageAdd    : "Mensaje agregado",
            MessageEdit   : "Mensaje editado",
            MessageDelete : "Mensaje eliminado",
            MessageImage  : "Imagen de mensaje eliminada",

            NoteAdd       : "Nota agregada",
            NoteEdit      : "Nota editada",
            NoteDelete    : "Nota eliminada",
            NoteImage     : "Imagen de nota eliminada",
        },
    },
    TicketType : {
        name    : "Tipo de ticket",
        actions : {
            Create : "Tipo de ticket creado",
            Edit   : "Tipo de ticket editado",
            Delete : "Tipo de ticket eliminado",
        },
    },
    TicketWaitlist : {
        name    : "Lista de espera de ticket",
        actions : {
            Create : "Lista de espera creada",
            Edit   : "Lista de espera editada",
            Delete : "Lista de espera eliminada",
        },
    },
    Document : {
        name    : "Documentación",
        actions : {
            Create : "Documento creado",
            Edit   : "Documento editado",
            Delete : "Documento eliminado",
        },
    },



    // Setup
    Admin : {
        name    : "Administrador",
        actions : {
            Create : "Administrador creado",
            Edit   : "Administrador editado",
            Delete : "Administrador eliminado",
        },
    },
    Plan : {
        name    : "Plan",
        actions : {
            Create : "Plan creado",
            Edit   : "Plan editado",
            Delete : "Plan eliminado",
        },
    },
    Addon : {
        name    : "Addon",
        actions : {
            Edit : "Addon editado",
        },
    },
    Currency : {
        name    : "Moneda",
        actions : {
            Create : "Moneda creada",
            Edit   : "Moneda editada",
            Delete : "Moneda eliminada",
        },
    },
    FlowTemplate : {
        name    : "Plantilla de flujo",
        actions : {
            Create  : "Plantilla de flujo creada",
            Edit    : "Plantilla de flujo editada",
            Copy    : "Plantilla de flujo copiado",
            Publish : "Plantilla de flujo publicada",
            Clear   : "Plantilla de flujo borrada",
            Delete  : "Plantilla de flujo eliminada",
        },
    },
    IntegrationTemplate : {
        name    : "Plantilla de integración",
        actions : {
            Create  : "Plantilla de integración creada",
            Edit    : "Plantilla de integración editada",
            Copy    : "Plantilla de integración copiado",
            Publish : "Plantilla de integración publicada",
            Clear   : "Plantilla de integración borrada",
            Delete  : "Plantilla de integración eliminada",
        },
    },
    Language : {
        name    : "Idioma",
        actions : {
            Create : "Idioma creado",
            Edit   : "Idioma editado",
            Delete : "Idioma eliminado",
        },
    },
    Country : {
        name    : "País",
        actions : {
            Edit : "País editado",
        },
    },
    Province : {
        name    : "Provincia",
        actions : {
            Edit : "Provincia editada",
        },
    },
    Media : {
        name    : "Archivo",
        actions : {
            Create : "Directorio creado",
            Upload : "Archivo subido",
            Rename : "Archivo renombrado",
            Move   : "Archivo movido",
            Delete : "Archivo eliminado",
        },
    },



    // Email
    EmailWhiteList : {
        name    : "Lista blanca de email",
        actions : {
            Add    : "Email agregado",
            Edit   : "Email editado",
            Remove : "Email quitado",
        },
    },
    EmailQueue : {
        name    : "Cola de email",
        actions : {
            Resend : "Email reenviado",
        },
    },



    // Log
    ProviderLog : {
        name    : "Log de proveedores",
        actions : {
            Delete : "Log de proveedores eliminado",
        },
    },
    QueryLog : {
        name    : "Query",
        actions : {
            Resolved : "Query resuelta",
            Delete   : "Query eliminada",
        },
    },
    ErrorLog : {
        name    : "Error",
        actions : {
            Resolved : "Error resuelto",
            Delete   : "Error eliminado",
        },
    },
};

export default actions;
