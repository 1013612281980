import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import useForm              from "Dashboard/Hooks/Form";

// Components
import AuthContainer        from "Components/Utils/Auth/AuthContainer";
import AuthHeader           from "Components/Utils/Auth/AuthHeader";
import AuthForm             from "Components/Utils/Auth/AuthForm";
import AuthSocial           from "Components/Utils/Auth/AuthSocial";
import GoogleLogin          from "Components/Utils/Login/GoogleLogin";
import MicrosoftLogin       from "Components/Utils/Login/MicrosoftLogin";
import FacebookLogin        from "Components/Utils/Login/FacebookLogin";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";
import Alert                from "Dashboard/Components/Form/Alert";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Join Page
 * @returns {React.ReactElement}
 */
function JoinPage() {
    const { organization : { name, hasCredential } } = Store.useState("auth");
    const {
        fetchOrganization, joinOrganization,
        googleJoin, microsoftJoin, facebookJoin,
    } = Store.useAction("auth");

    const { code } = Navigate.useParams();
    const navigate = Navigate.useGoto();
    const language = NLS.getLang();


    // The Current State
    const [ charging, setCharging ] = React.useState(true);

    // The Initial Data
    const initialData = {
        firstName : "",
        lastName  : "",
        email     : "",
        password  : "",
        code      : "",
        language  : "",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await joinOrganization({ ...data, code, language });
        navigate("LOGIN");
    };

    // Handles the Google Join
    const handleGoogle = async (params) => {
        try {
            const accessToken = params.access_token;
            await googleJoin({ accessToken, code, language });
            navigate("LOGIN");
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Handles the Microsoft Join
    const handleMicrosoft = async (params) => {
        try {
            const idToken = params.idToken;
            await microsoftJoin({ idToken, code, language });
            navigate("LOGIN");
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Handles the Facebook Join
    const handleFacebook = async (accessToken) => {
        try {
            await facebookJoin({ accessToken, code, language });
        } catch (errors) {
            setErrors(errors);
        }
    };

    // The Form State
    const {
        loading, data, errors, handleChange, handleSubmit, setErrors,
    } = useForm("join", initialData, handleEdit);


    // Load the Data
    React.useEffect(() => {
        handleData();
    }, []);

    // Handles the Load
    const handleData = async () => {
        await fetchOrganization(code);
        setCharging(false);
    };



    // Do the Render
    const isDisabled = Boolean(!charging && !name);

    return <AuthContainer>
        <AuthHeader
            title="JOIN_TITLE"
            subtitle={name}
            help={isDisabled ? "" : NLS.format("JOIN_HELP", name)}
        />
        {charging && <CircularLoader />}

        <Alert
            isHidden={!isDisabled}
            variant="error"
            message="JOIN_ERROR_TEAM"
        />
        {!charging && <>
            <AuthForm
                onSubmit={handleSubmit}
                error={errors.form}
                button="JOIN_BUTTON"
                isLoading={loading}
                isDisabled={isDisabled}
            >
                <InputField
                    isHidden={hasCredential}
                    name="firstName"
                    label="GENERAL_FIRST_NAME"
                    autoComplete="new-password"
                    value={data.firstName}
                    error={errors.firstName}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={hasCredential}
                    name="lastName"
                    label="GENERAL_LAST_NAME"
                    autoComplete="new-password"
                    value={data.lastName}
                    error={errors.lastName}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="email"
                    name="email"
                    label="GENERAL_EMAIL"
                    autoComplete="new-password"
                    value={data.email}
                    error={errors.email}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="password"
                    name="password"
                    label="GENERAL_PASSWORD"
                    autoComplete="new-password"
                    value={data.password}
                    error={errors.password}
                    onChange={handleChange}
                    isRequired
                />
            </AuthForm>

            <AuthSocial message="JOIN_SOCIAL">
                <GoogleLogin onSuccess={handleGoogle} isSignup />
                <MicrosoftLogin onSuccess={handleMicrosoft} isSignup />
                <FacebookLogin onSuccess={handleFacebook} isSignup />
            </AuthSocial>
        </>}
    </AuthContainer>;
}

export default JoinPage;
