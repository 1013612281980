import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The Admin Menu
 * @returns {React.ReactElement}
 */
function AdminMenu() {
    const { isPartner } = Store.useState("auth");
    const { closeMenu } = Store.useAction("core");


    // Do the Render
    if (!isPartner) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            variant="darker"
            url="DASHBOARD"
            icon="dashboard"
            message="DASHBOARD_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="darker"
            url="CLIENTS"
            icon="client"
            message="CLIENTS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="darker"
            url="USERS"
            icon="user"
            message="USERS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            variant="darker"
            url="SUBSCRIPTIONS"
            icon="subscription"
            message="SUBSCRIPTIONS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default AdminMenu;
