import React                from "react";
import Action               from "Dashboard/Core/Action";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useList              from "Dashboard/Hooks/List";

// Components
import EmailQueueView       from "./EmailQueueView";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";



/**
 * The Email Queue List
 * @returns {React.ReactElement}
 */
function EmailQueueList() {
    const { fetch, load, loadFilter } = useList("emailQueue");

    const data = Store.useState("emailQueue");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { resendElem } = Store.useAction("emailQueue");

    // The Current State
    const [ checked, setChecked ] = React.useState([]);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Close
    const handleClose = (update) => {
        endAction();
        if (update) {
            fetch();
        }
    };

    // Handles the Resend Submit
    const handleResend = async () => {
        const result = await resendElem(elemID || checked);
        if (result.success) {
            setChecked([]);
            fetch();
        }
        endAction();
    };


    // Do the Render
    const canMulti   = canEdit && checked.length > 0;
    const itemAmount = elemID ? 1 : checked.length;

    return <Main>
        <Header message="EMAIL_QUEUE_NAME" icon="email-queue">
            <ActionList onAction={startAction}>
                <ActionItem
                    isHidden={!canMulti}
                    action="RESEND"
                    message="EMAIL_QUEUE_RESEND_TITLE"
                />
            </ActionList>
        </Header>

        <Content>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            />
            <Table
                fetch={load}
                sort={sort}
                none="EMAIL_QUEUE_NONE_AVAILABLE"
                isLoading={loading}
                checked={checked}
                setChecked={setChecked}
                hasCheckAll
            >
                <TableHead>
                    <TableHeader field="createdTime" message="GENERAL_CREATED" maxWidth="150" />
                    <TableHeader field="subject"     message="GENERAL_SUBJECT" />
                    <TableHeader field="sendTo"      message="GENERAL_SEND_TO" />
                    <TableHeader field="sentTime"    message="GENERAL_SENT_TO" maxWidth="150" />
                    <TableHeader field="isError"     message="GENERAL_RESULT"  maxWidth="90" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.emailID} elemID={elem.emailID}>
                        <TableCell message={elem.createdDateText} />
                        <TableCell message={elem.subject}         />
                        <TableCell message={elem.sendToText}      />
                        <TableCell message={elem.sentDateText}    />
                        <TableCell message={elem.errorText}       className={elem.errorClass} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction}>
                    <TableAction action="VIEW"   message="EMAIL_QUEUE_VIEW_TITLE"   />
                    <TableAction action="RESEND" message="EMAIL_QUEUE_RESEND_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <EmailQueueView
            open={action.isView}
            elemID={elemID}
            onClose={handleClose}
        />
        <ConfirmDialog
            open={action.isResend}
            icon="send"
            title="EMAIL_QUEUE_RESEND_TITLE"
            message={NLS.pluralize("EMAIL_QUEUE_RESEND_TEXT", itemAmount)}
            onSubmit={handleResend}
            onClose={endAction}
        />
    </Main>;
}

export default EmailQueueList;
