import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Components
import ConversationsItem    from "Components/Utils/Details/ConversationsItem";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";



/**
 * The User Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserDetails(props) {
    const { startAction } = props;
    const { canEdit, elem, userTeams, conversations } = Store.useState("user");


    // Handles the Action
    const handleAction = (action) => {
        startAction(action, elem.id);
    };


    // Do the Render
    return <Details
        isHidden={!elem.id}
        collapsible="user"
        onAction={handleAction}
        canEdit={canEdit}
        isInside
    >
        <DetailList
            message="USERS_SINGULAR"
            action="EDIT"
        >
            <DetailItem
                icon="name"
                message={elem.displayName}
                tooltip="GENERAL_NAME"
            />
            <DetailItem
                icon="email"
                message={elem.email}
                tooltip="GENERAL_EMAIL"
                isEmail
            />
            <DetailItem
                icon="phone"
                message={elem.phone}
                tooltip="GENERAL_PHONE"
            />
            <DetailItem
                icon="access"
                message={elem.accessName}
                tooltip="GENERAL_ACCESS"
            />
            <DetailItem
                icon="status"
                message={elem.statusName}
                tooltip="GENERAL_STATUS"
            />
            <DetailItem
                icon="observations"
                message={elem.observations}
                tooltip="GENERAL_OBSERVATIONS"
            />
            <DetailItem
                className={elem.qualificationColor}
                icon={elem.qualificationIcon}
                message={elem.qualificationPercent}
                prefix="CONVERSATIONS_QUALIFICATION"
                tooltip={elem.qualificationTooltip}
            />
            <DetailItem
                icon="time"
                message={elem.createdDate}
                tooltip="GENERAL_CREATED_TIME"
            />
        </DetailList>

        <DetailList
            message="TEAMS_NAME"
            action="TEAM"
            isHidden={!userTeams.length}
        >
            {userTeams.map((elem) => <DetailItem
                key={elem.teamID}
                icon="team"
                message={elem.name}
                tooltip="TEAMS_SINGULAR"
            />)}
        </DetailList>

        <ConversationsItem
            list={conversations}
        />
    </Details>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserDetails.propTypes = {
    startAction : PropTypes.func.isRequired,
};

export default UserDetails;
