import React                from "react";
import PropTypes            from "prop-types";

// Components
import AssistantRunContent  from "./AssistantRunContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";



/**
 * The Assistant Run Log List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AssistantRunLogList(props) {
    const { type } = props;


    // Do the Render
    return <Main>
        <Header message="ASSISTANTS_RUNS_NAME" icon="assistant-log" />
        <Content>
            <AssistantRunContent
                type={type}
                withNavigate
            />
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AssistantRunLogList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default AssistantRunLogList;
