import React                from "react";




/**
 * Returns true if the value is in the options
 * @param {String}   value
 * @param {String[]} options
 * @returns {Boolean}
 */
function useHasData(value, options) {
    return React.useMemo(() => {
        return options.includes(value);
    }, [ value ]);
}

/**
 * Returns true if the Trigger has a Contact Date
 * @param {String} triggerName
 * @returns {Boolean}
 */
function useHasContactDate(triggerName) {
    return useHasData(triggerName, [
        "AfterContactDate",
        "BeforeContactDate",
    ]);
}

/**
 * Returns true if the Trigger has a Time Amount
 * @param {String} triggerName
 * @returns {Boolean}
 */
function useHasTimeAmount(triggerName) {
    return useHasData(triggerName, [
        "InTime",
        "AfterResolved",
        "AfterBooking",
        "AfterCheckIn",
        "AfterCheckOut",
    ]);
}

/**
 * Returns true if the Trigger has Days
 * @param {String} triggerName
 * @returns {Boolean}
 */
function useHasDays(triggerName) {
    return useHasData(triggerName, [
        "BeforeCheckIn",
        "BeforeCheckOut",
    ]);
}

/**
 * Returns true if the Trigger has a WeekDay
 * @param {String} triggerName
 * @returns {Boolean}
 */
function useHasWeekDay(triggerName) {
    return useHasData(triggerName, [
        "Weekly",
    ]);
}

/**
 * Returns true if the Trigger has a Day Position
 * @param {String} triggerName
 * @returns {Boolean}
 */
function useHasDayPosition(triggerName) {
    return useHasData(triggerName, [
        "Monthly",
    ]);
}

/**
 * Returns true if the Trigger has a Date
 * @param {String} triggerName
 * @returns {Boolean}
 */
function useHasDate(triggerName) {
    return useHasData(triggerName, [
        "Date",
    ]);
}

/**
 * Returns true if the Trigger has a Time
 * @param {String} triggerName
 * @returns {Boolean}
 */
function useHasTime(triggerName) {
    return useHasData(triggerName, [
        "Date",
        "Daily",
        "Weekly",
        "Monthly",
        "BeforeCheckIn",
        "BeforeCheckOut",
    ]);
}



/**
 * Returns true if the Condition has a Contact Field
 * @param {String} conditionName
 * @returns {Boolean}
 */
function useHasContactField(conditionName) {
    return useHasData(conditionName, [
        "ContactField",
    ]);
}

/**
 * Returns true if the Condition has a Contact Tongue
 * @param {String} conditionName
 * @returns {Boolean}
 */
function useHasContactTongue(conditionName) {
    return useHasData(conditionName, [
        "ContactTongue",
    ]);
}

/**
 * Returns true if the Condition has a Tag
 * @param {String} conditionName
 * @returns {Boolean}
 */
function useHasTag(conditionName) {
    return useHasData(conditionName, [
        "ContactTag",
        "ConversationTag",
    ]);
}

/**
 * Returns true if the Condition requires Before or After
 * @param {String} conditionName
 * @returns {Boolean}
 */
function requiresBeforeOrAfter(conditionName) {
    return [
        "ContactDate",
        "ConversationDate",
    ].includes(conditionName);
}

/**
 * Returns true if the Condition has a Conversation Field
 * @param {String} conditionName
 * @returns {Boolean}
 */
function useHasConversationField(conditionName) {
    return useHasData(conditionName, [
        "ConversationField",
    ]);
}

/**
 * Returns true if the Condition has a Conversation Team
 * @param {String} conditionName
 * @returns {Boolean}
 */
function useHasConversationTeam(conditionName) {
    return useHasData(conditionName, [
        "ConversationTeam",
    ]);
}

/**
 * Returns true if the Condition has a Hospitality Field
 * @param {String} conditionName
 * @returns {Boolean}
 */
function useHasHospitalityField(conditionName) {
    return useHasData(conditionName, [
        "HospitalityField",
    ]);
}

/**
 * Returns true if the Condition has a Hotel
 * @param {String} conditionName
 * @returns {Boolean}
 */
function useHasHotel(conditionName) {
    return useHasData(conditionName, [
        "HospitalityBooking",
        "HospitalityIn",
        "HospitalityOut",
    ]);
}

/**
 * Returns true if the Condition has a Value
 * @param {String} conditionName
 * @returns {Boolean}
 */
function useHasValue(conditionName) {
    return useHasData(conditionName, [
        "ContactDate",
        "ContactField",
        "ConversationDate",
        "ConversationField",
        "HospitalityField",
    ]);
}

/**
 * Returns true if the Condition has a Value Date
 * @param {String} conditionName
 * @returns {Boolean}
 */
function useHasValueDate(conditionName) {
    return useHasData(conditionName, [
        "ContactDate",
        "ConversationDate",
    ]);
}



/**
 * Returns true if the Campaign will be send only once
 * @param {Array.<Object>} triggers
 * @returns {Boolean}
 */
function willSendOnce(triggers) {
    return triggers.filter((item) => {
        return [ "InTime", "Date" ].includes(item.triggerName);
    }).length > 0;
}

/**
 * Returns true if the Campaign will be send multiple times
 * @param {Array.<Object>} triggers
 * @returns {Boolean}
 */
function willSendMultiple(triggers) {
    return triggers.filter((item) => {
        return [ "Daily", "Weekly", "Monthly" ].includes(item.triggerName);
    }).length > 0;
}

/**
 * Returns true if the Campaign will be send with SMS
 * @param {Object.<Number, Object>} options
 * @returns {Boolean}
 */
function willSendSMS(options) {
    return Object.values(options).filter((item) => {
        return Number(item.smsActive) === 1;
    }).length > 0;
}

/**
 * Returns true if the Campaign will be send with WhatsApp
 * @param {Object.<Number, Object>} options
 * @returns {Boolean}
 */
function willSendWhatsApp(options) {
    return Object.values(options).filter((item) => {
        return Number(item.whatsAppActive) === 1;
    }).length > 0;
}




// The public API
export default {
    useHasContactDate,
    useHasTimeAmount,
    useHasDays,
    useHasWeekDay,
    useHasDayPosition,
    useHasDate,
    useHasTime,

    useHasContactField,
    useHasContactTongue,
    useHasTag,
    useHasConversationField,
    useHasConversationTeam,
    useHasHospitalityField,
    useHasHotel,
    useHasValue,
    useHasValueDate,
    requiresBeforeOrAfter,

    willSendOnce,
    willSendMultiple,
    willSendSMS,
    willSendWhatsApp,
};
