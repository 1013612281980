import React                from "react";
import PropTypes            from "prop-types";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import SubscriptionItems    from "./View/SubscriptionItems";
import SubscriptionWabas    from "./View/SubscriptionWabas";
import SubscriptionSMS      from "./View/SubscriptionSMS";
import SubscriptionAI       from "./View/SubscriptionAI";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Subscription View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionView(props) {
    const { open, forAdmin, elemID, onClose } = props;


    // The Current State
    const [ tab, setTab ] = React.useState("info");

    // Load the Data
    const { loading, elem } = useDialog("subscription", open, elemID, { forAdmin });


    // Variables
    const hasWabas = elem.wabas.length > 0;
    const hasSMS   = elem.sms.length > 0;
    const hasAI    = elem.assistants.length > 0;
    const hasTabs  = hasWabas || hasSMS || hasAI;


    // Do the Render
    return <ViewDialog
        open={open}
        icon="subscription"
        title="SUBSCRIPTIONS_VIEW_TITLE"
        onClose={onClose}
        isLoading={loading}
        isWide
        withSpacing
    >
        <Columns amount="4">
            <ViewField
                label="PERIOD_NAME"
                value={elem.createdPeriod}
            />
            <ViewField
                label="GENERAL_CREATED_TIME"
                value={elem.createdDate}
            />

            <ViewField
                isHidden={!forAdmin}
                label="PARTNERS_SINGULAR"
                value={elem.partnerName}
                showEmpty
            />
            <ViewField
                label="CLIENTS_SINGULAR"
                value={elem.clientName}
                viewClass={`text-${elem.statusColor}`}
            />
            <ViewField
                label="GENERAL_SOCIAL_REASON"
                value={elem.clientSocialReason}
                showEmpty
            />
            <ViewField
                label="GENERAL_TAX_ID"
                value={elem.clientTaxID}
                showEmpty
            />

            <ViewField
                label="CLIENTS_CAMPAIGN_DISPATCHES"
                value={elem.campaignText}
            />
            <ViewField
                label="CLIENTS_INTEGRATION_EXECUTIONS"
                value={elem.integrationText}
            />
            <ViewField
                label="PARTNERS_COMMISSION"
                value={elem.commissionText}
            />
        </Columns>

        <TabList
            isHidden={!hasTabs}
            selected={tab}
            onClick={setTab}
            inDialog
        >
            <TabItem
                message="GENERAL_INFO"
                value="info"
            />
            <TabItem
                isHidden={!hasWabas}
                message="GENERAL_WHATSAPP_CHANNEL"
                value="wabas"
            />
            <TabItem
                isHidden={!hasSMS}
                message="GENERAL_SMS_CHANNEL"
                value="sms"
            />
            <TabItem
                isHidden={!hasAI}
                message="ASSISTANTS_NAME"
                value="assistants"
            />
        </TabList>

        <SubscriptionItems
            isHidden={tab !== "info"}
            elem={elem}
        />
        <SubscriptionWabas
            isHidden={tab !== "wabas"}
            elem={elem}
        />
        <SubscriptionSMS
            isHidden={tab !== "sms"}
            elem={elem}
        />
        <SubscriptionAI
            isHidden={tab !== "assistants"}
            elem={elem}
        />
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionView.propTypes = {
    open     : PropTypes.bool.isRequired,
    forAdmin : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default SubscriptionView;
