import React                from "react";
import PropTypes            from "prop-types";
import { Bar, Doughnut }    from "react-chartjs-2";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ReportsValues        from "./ReportsValues";



/**
 * The Reports Chart
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReportsChart(props) {
    const {
        type, isStacked, title, labels, icons, dataset,
        extras, colors, suffix, urls, withLinks,
    } = props;


    // Chart Data
    const options = {
        aspectRatio         : 1.8,
        maintainAspectRatio : true,
    };
    const tooltip = {
        cornerRadius  : 10,
        padding       : 8,
        boxPadding    : 4,
        usePointStyle : true,
        callbacks     : {
            label(context) {
                if (context.dataset.label) {
                    return `${context.dataset.label}: ${context.raw}${suffix}`;
                }
                return `${context.raw}${suffix}`;
            },
        },
    };


    // Do the Render
    if (type === "values") {
        return <ReportsValues
            title={title}
            labels={labels}
            icons={icons}
            dataset={dataset}
            extras={extras}
            urls={urls}
            withLinks={withLinks}
        />;
    }

    if (type === "bar") {
        return <Bar
            options={{
                ...options,
                interaction : {
                    mode      : "index",
                    intersect : false,
                },
                scales : {
                    x : {
                        stacked : isStacked,
                        grid    : { display : false },
                    },
                    y : {
                        stacked : isStacked,
                        min     : 0,
                        grid    : { display : false },
                        ticks   : {
                            precision : 0,
                            callback(label, index, labels) {
                                return label + suffix;
                            },
                        },
                    },
                },
                plugins : {
                    tooltip,
                    legend : {
                        display : false,
                    },
                },
            }}
            data={{
                labels,
                datasets : isStacked ? dataset : [{
                    data            : dataset,
                    borderWidth     : 1,
                    borderRadius    : 6,
                    borderColor     : "hsl(216, 98%, 44%)",
                    backgroundColor : "hsla(216, 98%, 44%, 0.8)",
                }],
            }}
        />;
    }

    if (type === "sidebar") {
        return <Bar
            options={{
                ...options,
                indexAxis : "y",
                scales    : {
                    x : {
                        min   : 0,
                        grid  : { display : false },
                        ticks : {
                            precision : 0,
                            callback(label, index, labels) {
                                return label + suffix;
                            },
                        },
                    },
                    y : {
                        grid  : { display : false },
                        ticks : {
                            callback(label) {
                                return Utils.makeShort(labels[label], 20);
                            },
                        },
                    },
                },
                plugins : {
                    tooltip,
                    legend : {
                        display : false,
                    },
                },
            }}
            data={{
                labels,
                datasets : colors.length ? [{
                    data            : dataset,
                    borderRadius    : 6,
                    borderWidth     : 0,
                    backgroundColor : colors,
                }] : [{
                    data            : dataset,
                    borderRadius    : 6,
                    borderWidth     : 1,
                    borderColor     : "hsl(216, 98%, 44%)",
                    backgroundColor : "hsla(216, 98%, 44%, 0.8)",
                }],
            }}
        />;
    }

    if (type === "doughnut") {
        return <Doughnut
            options={{
                ...options,
                plugins : {
                    tooltip,
                    legend : {
                        position : "left",
                        labels   : {
                            usePointStyle : true,
                            generateLabels(chart) {
                                const datasets = chart.data.datasets;
                                return datasets[0].data.map((data, i) => ({
                                    text        : `${chart.data.labels[i]}: ${data}`,
                                    fillStyle   : datasets[0].backgroundColor[i],
                                    strokeStyle : "white",
                                    index       : i,
                                }));
                            },
                        },
                    },
                },
            }}
            data={{
                labels,
                datasets : [{
                    data            : dataset,
                    backgroundColor : colors,
                }],
            }}
        />;
    }

    return <React.Fragment />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReportsChart.propTypes = {
    type      : PropTypes.string.isRequired,
    isStacked : PropTypes.bool.isRequired,
    title     : PropTypes.string.isRequired,
    labels    : PropTypes.array.isRequired,
    icons     : PropTypes.array.isRequired,
    dataset   : PropTypes.array.isRequired,
    extras    : PropTypes.array.isRequired,
    colors    : PropTypes.array.isRequired,
    suffix    : PropTypes.string.isRequired,
    urls      : PropTypes.array.isRequired,
    withLinks : PropTypes.bool.isRequired,
};

export default ReportsChart;
