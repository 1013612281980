import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import KeyCode              from "Dashboard/Utils/KeyCode";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";

// Components
import ReplyContent         from "./ReplyContent";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Content = Styled.div`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px - 6px);
    gap: 8px;
`;

const Input = Styled(InputField)`
    .input-content > div {
        border-radius: 0;
    }
    .input-content {
        box-sizing: border-box;
        min-height: 32px;
        padding: 8px;
    }
    .input-textarea {
        padding: 1px;
    }
    .inputfield-editor {
        margin-bottom: -8px;
    }
`;

const Link = Styled(IconLink)`
    font-size: 18px;
`;



/**
 * The Reply Message
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReplyMessage(props) {
    const {
        isHidden, inputRef, conversationHash, forNote,
        replyToID, replyMessage, onReplyRemove,
        files, onFileMultiAdd, onFileRemove, onFileError,
        text, setText,
        onSubmit,
    } = props;

    const conversationsNavigate = Conversations.useGoto();

    const { isClientUser } = Store.useState("auth");

    const { elem, messageLength } = Store.useState("conversation");
    const { lastUpdate } = Store.useState("conversationMessage");

    const { setWriting } = Store.useAction("conversation");
    const { addMessage, sendMessage } = Store.useAction("conversationMessage");
    const { createNote } = Store.useAction("conversationNote");


    // The References
    const waitUntilRef = React.useRef(0);
    const timerRef     = React.useRef(null);

    // The Current State
    const [ sending, setSending ] = React.useState(false);
    const [ error,   setError   ] = React.useState("");


    // Focus the Input if the conversation changes
    React.useEffect(() => {
        window.setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 200);
    }, [ conversationHash ]);

    // Handles the Message Change
    const handleChange = (name, value) => {
        setText(value);
        setError("");
    };

    // Handle Pasting Files
    const handlePaste = (e) => {
        const maxSize    = Number(process.env.REACT_APP_MAX_SIZE);
        const items      = e.clipboardData.items;
        const files      = [];
        let   totalFiles = 0;

        for (const item of items) {
            if (item.kind === "file") {
                const file = item.getAsFile();
                if (Utils.isValidFile(file, false, maxSize)) {
                    files.push(file);
                }
                totalFiles += 1;
            }
        }

        if (files.length) {
            onFileMultiAdd(files);
            onReplyRemove();
        }
        if (totalFiles !== files.length) {
            onFileError(totalFiles - files.length);
        }
        if (totalFiles) {
            e.preventDefault();
        }
    };

    // Handles the Message KeyDown
    const handleKeyDown = (e) => {
        if (Date.now() >= waitUntilRef.current) {
            setWriting(elem.id, 1);
            Utils.setTimeout(timerRef, () => {
                setWriting(elem.id, 0);
            }, 5 * 1000);
            waitUntilRef.current = Date.now() + 2000;
        }

        if (e.keyCode === KeyCode.DOM_VK_RETURN && !e.shiftKey) {
            handleSubmit(e);
            e.preventDefault();
        }
    };

    // Handles the Message Submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const message = String(text).trim();
        if (sending || (!message && !files.length)) {
            setError("CONVERSATIONS_ERROR_REPLY");
            return;
        }

        setSending(true);
        try {
            let   response = {};
            const fields   = {
                message, replyToID, lastUpdate,
                conversationID : elem.id,
                fileAmount     : files.length,
            };
            for (const [ index, file ] of files.entries()) {
                const key = forNote ? "" : index;
                if (file.file) {
                    fields[`file${key}`] = file.file;
                }
                fields[`fileName${key}`] = file.name;
            }

            if (forNote) {
                response = await createNote(fields);
            } else {
                response = await addMessage(fields);
                if (!response.error && !elem.isWidget) {
                    sendMessage(response.messageID, lastUpdate);
                }
            }
            if (!response.error) {
                setText("");
                onReplyRemove();
                onFileRemove(0, true);
            }

            setSending(false);
            onSubmit();
            window.setTimeout(() => inputRef.current.focus(), 100);

            if (!response.error && elem.isQueue && isClientUser) {
                conversationsNavigate("PROGRESS", elem.hash);
            }
        } catch (errors) {
            setSending(false);
        }
    };



    // Do the Render
    const showReplyTo = Boolean(replyToID && !forNote);

    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container>
        <Content>
            <ReplyContent
                isHidden={!showReplyTo}
                title="CONVERSATIONS_REPLYING_TO"
                message={replyMessage}
                onClose={onReplyRemove}
            />
            {files.map((file, index) => <ReplyContent
                key={index}
                title="CONVERSATIONS_ATTACHMENT"
                message={file.name}
                onClose={() => onFileRemove(index)}
            />)}
            <Input
                passedRef={inputRef}
                type="textarea"
                name="message"
                placeholder={forNote ? "CONVERSATIONS_NOTES_WRITE" : "CONVERSATIONS_WRITE_TEXT"}
                value={text}
                error={error}
                onPaste={handlePaste}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                isDisabled={sending}
                maxLength={messageLength}
                withEditor={false}
                rows="1"
                maxRows="8"
                hasClear
            />
        </Content>
        <Link
            variant="primary"
            icon="send"
            tooltip="GENERAL_SEND"
            tooltipVariant="top"
            onClick={handleSubmit}
            onTouchEnd={handleSubmit}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReplyMessage.propTypes = {
    isHidden         : PropTypes.bool,
    inputRef         : PropTypes.any,
    conversationHash : PropTypes.string,
    forNote          : PropTypes.bool,
    replyToID        : PropTypes.number,
    replyMessage     : PropTypes.string,
    onReplyRemove    : PropTypes.func,
    files            : PropTypes.array.isRequired,
    onFileMultiAdd   : PropTypes.func.isRequired,
    onFileRemove     : PropTypes.func.isRequired,
    onFileError      : PropTypes.func.isRequired,
    text             : PropTypes.string,
    setText          : PropTypes.func,
    onSubmit         : PropTypes.func,
};

export default ReplyMessage;
