import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";
import FormatMenu           from "Components/Utils/Menus/FormatMenu";
import VariableMenu         from "Components/Utils/Menus/VariableMenu";
import ChannelMenu          from "Components/Utils/Menus/ChannelMenu";

// Dashboard
import EmojiPopup           from "Dashboard/Components/Form/EmojiPopup";
import Menu                 from "Dashboard/Components/Menu/Menu";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";



// Styles
const FileInput = Styled.input`
    display: none;
`;



/**
 * The Reply Menus
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReplyMenus(props) {
    const {
        inputRef, msgTemplateMenuRef, formatMenuRef,
        emojiMenuRef, fileMenuRef, fileInputRef,
        variableMenuRef, channelMenuRef,
        msgTemplates, text, onText, menu, onMenu,
        forNote, onFileAdd, onFileMultiAdd, onFileError,
        onReplyRemove,
    } = props;

    const { elem, variables, channelLinks } = Store.useState("conversation");


    // The Current State
    const [ mediaOpen, setMediaOpen ] = React.useState(false);


    // Handles the Template Click
    const handleTemplateClick = (msgTemplate) => {
        onText(msgTemplate.message);
        if (msgTemplate.file) {
            onFileAdd(null, msgTemplate.file, true);
        }
    };

    // Handles the Emoji Click
    const handleEmojiClick = (emoji) => {
        onText(text + emoji);
        onMenu("");
    };

    // Handles the File Open
    const handleFileClick = () => {
        fileInputRef.current.click();
        onMenu("");
    };

    // Handles the File Change
    const handleFileChange = (e) => {
        if (!e.target.files.length) {
            return;
        }

        const files      = [];
        const totalFiles = e.target.files.length;
        const maxSize    = Number(process.env.REACT_APP_MAX_SIZE);

        for (const file of e.target.files) {
            if (Utils.isValidFile(file, false, maxSize)) {
                files.push(file);
            }
        }

        if (files.length) {
            onFileMultiAdd(files);
            onReplyRemove();
        }
        if (totalFiles !== files.length) {
            onFileError(totalFiles - files.length);
        }
    };

    // Handles the Media File
    const handleMediaFile = (files) => {
        if (files) {
            onFileMultiAdd(files.map((name) => ({ name })));
        }
        setMediaOpen(false);
        onReplyRemove();
    };

    // Handles the Variable Click
    const handleVariableClick = (message) => {
        onText(message);
        onMenu("");
    };



    // Do the Render
    return <>
        <Menu
            open={menu === "msgTemplate"}
            targetRef={msgTemplateMenuRef}
            direction="top left"
            gap={4}
            onClose={() => onMenu("")}
            maxHeight={300}
            withSearch
        >
            {msgTemplates.map((elem) => <MenuItem
                key={elem.key}
                message={elem.value}
                onClick={() => handleTemplateClick(elem)}
            />)}
        </Menu>

        <Menu
            open={menu === "file"}
            targetRef={fileMenuRef}
            direction="top left"
            gap={4}
            onClose={() => onMenu("")}
        >
            <MenuItem
                message="CONVERSATIONS_NEW_FILE"
                onClick={handleFileClick}
            />
            <MenuItem
                message="CONVERSATIONS_OLD_FILE"
                onClick={() => setMediaOpen(true)}
            />
        </Menu>

        <FormatMenu
            open={menu === "format"}
            targetRef={formatMenuRef}
            inputRef={inputRef}
            message={text}
            direction="top left"
            onText={onText}
            onClose={() => onMenu("")}
        />
        <VariableMenu
            open={menu === "variable"}
            targetRef={variableMenuRef}
            inputRef={inputRef}
            message={text}
            list={variables}
            direction="top left"
            onClick={handleVariableClick}
            onClose={() => onMenu("")}
        />
        <ChannelMenu
            open={menu === "channel"}
            targetRef={channelMenuRef}
            inputRef={inputRef}
            message={text}
            list={channelLinks}
            direction="top left"
            onClick={handleVariableClick}
            onClose={() => onMenu("")}
        />
        <EmojiPopup
            open={menu === "emoji"}
            targetRef={emojiMenuRef}
            direction="top left"
            gap={4}
            onClick={handleEmojiClick}
            onClose={() => onMenu("")}
        />
        <FileInput
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            multiple={!forNote}
        />
        <MediaDialog
            open={mediaOpen}
            clientID={elem.clientID}
            onSubmit={handleMediaFile}
            onClose={() => setMediaOpen(false)}
            multipleSelect={!forNote}
            isRequired
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReplyMenus.propTypes = {
    inputRef           : PropTypes.any.isRequired,
    msgTemplateMenuRef : PropTypes.any.isRequired,
    formatMenuRef      : PropTypes.any.isRequired,
    emojiMenuRef       : PropTypes.any.isRequired,
    fileMenuRef        : PropTypes.any.isRequired,
    fileInputRef       : PropTypes.any.isRequired,
    variableMenuRef    : PropTypes.any.isRequired,
    channelMenuRef     : PropTypes.any.isRequired,
    msgTemplates       : PropTypes.array.isRequired,
    text               : PropTypes.string.isRequired,
    onText             : PropTypes.func.isRequired,
    menu               : PropTypes.string.isRequired,
    onMenu             : PropTypes.func.isRequired,
    forNote            : PropTypes.bool.isRequired,
    onFileAdd          : PropTypes.func.isRequired,
    onFileMultiAdd     : PropTypes.func.isRequired,
    onFileError        : PropTypes.func.isRequired,
    onReplyRemove      : PropTypes.func.isRequired,
};

export default ReplyMenus;
