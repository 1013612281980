import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import OrganizationPage     from "Components/App/Profile/OrganizationPage";
import SettingsPage         from "Components/App/Profile/SettingsPage";
import ActionLogList        from "Components/App/Log/ActionLog/ActionLogList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import UserRoute            from "Dashboard/Components/Route/UserRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Profile Container
 * @returns {React.ReactElement}
 */
function ProfileContainer() {
    const { credential, hasOrganizations } = Store.useState("auth");


    // Do the Render
    return <>
        <SecondaryNav
            message={credential.name}
            fallback="ACCOUNT_MINE"
        >
            <NavigationItem
                isHidden={!hasOrganizations}
                message="ORGANIZATIONS_NAME"
                url="ORGANIZATIONS"
                icon="client"
            />
            <NavigationItem
                message="GENERAL_SETTINGS"
                url="SETTINGS"
                icon="settings"
            />
            <NavigationItem
                message="ACTIONS_LOG_NAME"
                url="LOG_ACTIONS"
                icon="action-log"
            />
        </SecondaryNav>

        <Router type="PROFILE" withMain>
            <UserRoute
                isHidden={!hasOrganizations}
                url="ORGANIZATIONS"
                component={OrganizationPage}
            />
            <UserRoute
                url="SETTINGS"
                component={SettingsPage}
            />
            <UserRoute
                url="LOG_ACTIONS"
                component={ActionLogList}
            />
        </Router>
    </>;
}

export default ProfileContainer;
