// The App Params
const params = {
    "CODE"              : "code",
    "DASHBOARD_TAB"     : "dashboardTab",
    "CLIENT"            : "clientID",
    "PARTNER"           : "partnerID",
    "CONVERSATION"      : "conversationHash",
    "CONVERSATION_TAB"  : "conversationTab",
    "STORE"             : "storeID",
    "STORE_TAB"         : "storeTab",
    "STORE_SUB"         : "storeSubID",
    "CONTACT"           : "contactID",
    "HOSPITALITY"       : "hospitalityID",
    "ACCOUNT"           : "accountID",
    "CAMPAIGN"          : "campaignID",
    "CAMPAIGN_TAB"      : "campaignTab",
    "USER"              : "userID",
    "MSG_TEMPLATE"      : "msgTemplateID",
    "QUESTIONNAIRE"     : "questionnaireID",
    "SCHEDULE"          : "scheduleID",
    "HOTEL"             : "hotelID",
    "WABA_TEMPLATE"     : "wabaTemplateID",
    "ASSISTANT"         : "assistantID",
    "WIDGET"            : "widgetID",
    "WIDGET_TAB"        : "widgetTab",
    "FLOW"              : "flowID",
    "FLOW_TAB"          : "flowTab",
    "INTEGRATION"       : "integrationID",
    "INTEGRATION_TAB"   : "integrationTab",
    "CONNECTOR"         : "connectorID",
    "TICKET"            : "ticketID",
    "TICKET_TAB"        : "ticketTab",
    "DOCUMENT_LANGUAGE" : "documentLanguage",
    "DOCUMENT_TYPE"     : "documentType",
    "DOCUMENT_SLUG"     : "documentSlug",
    "LOG"               : "logID",
};

export default params;
