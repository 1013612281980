import React                from "react";
import PropTypes            from "prop-types";

// Components
import FetchLogContent      from "./FetchLogContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";



/**
 * The Fetch Log List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FetchLogList(props) {
    const { type } = props;


    // Do the Render
    return <Main>
        <Header message="FETCHES_LOG_NAME" icon="fetch" />
        <Content>
            <FetchLogContent
                type={type}
                withNavigate
            />
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FetchLogList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default FetchLogList;
