import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import useForm              from "Dashboard/Hooks/Form";

// Components
import AuthContainer        from "Components/Utils/Auth/AuthContainer";
import AuthHeader           from "Components/Utils/Auth/AuthHeader";
import AuthForm             from "Components/Utils/Auth/AuthForm";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Reset Password Page
 * @returns {React.ReactElement}
 */
function ResetPassPage() {
    const { resetPass } = Store.useAction("auth");

    const { code } = Navigate.useParams();
    const navigate = Navigate.useGoto();


    // The Initial Data
    const initialData = {
        code            : "",
        password        : "",
        confirmPassword : "",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        data.code = code;
        await resetPass(data);
        navigate("LOGIN");
    };

    // The Form State
    const {
        loading, data, errors, handleChange, handleSubmit,
    } = useForm("resetPass", initialData, handleEdit, null, false);



    // Do the Render
    return <AuthContainer>
        <AuthHeader
            title="RESET_TITLE"
            help="RESET_HELP"
        />
        <AuthForm
            url="LOGIN"
            link="RESET_ACCOUNT"
            button="RESET_BUTTON"
            error={errors.form}
            onSubmit={handleSubmit}
            isLoading={loading}
        >
            <InputField
                type="password"
                name="password"
                label="GENERAL_PASSWORD"
                autoComplete="new-password"
                value={data.password}
                error={errors.password}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="password"
                name="confirmPassword"
                label="GENERAL_PASSWORD_CONFIRM"
                autoComplete="new-password"
                value={data.confirmPassword}
                error={errors.confirmPassword}
                onChange={handleChange}
                isRequired
            />
        </AuthForm>
    </AuthContainer>;
}

export default ResetPassPage;
