import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";

// Components
import NotificationLogView  from "./NotificationLogView";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Notification Log List
 * @returns {React.ReactElement}
 */
function NotificationLogList() {
    const { load, loadFilter } = useList("notificationLog");

    const data = Store.useState("notificationLog");
    const { loading, list, total, filters, sort } = data;

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Do the Render
    return <Main>
        <Header message="NOTIFICATIONS_NAME" icon="notification" />
        <Content>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            />
            <Table
                fetch={load}
                sort={sort}
                none="NOTIFICATIONS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="createdTime"    message="GENERAL_CREATED_FEM" maxWidth="150" />
                    <TableHeader field="title"          message="GENERAL_TITLE"       />
                    <TableHeader field="credentialName" message="GENERAL_SEND_TO"     />
                    <TableHeader field="sentTime"       message="GENERAL_SENT_TO_FEM" maxWidth="150" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.notificationID} elemID={elem.notificationID}>
                        <TableCell message={elem.createdDateText} />
                        <TableCell message={elem.title}           />
                        <TableCell message={elem.credentialName}  />
                        <TableCell message={elem.sentDateText}    />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction}>
                    <TableAction action="VIEW" message="NOTIFICATIONS_VIEW_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <NotificationLogView
            open={action.isView}
            elemID={elemID}
            onClose={endAction}
        />
    </Main>;
}

export default NotificationLogList;
