import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Hospitality Import Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HospitalityImport(props) {
    const { open, clientID, onClose, onSubmit } = props;

    const { uploadImport, importHospitality } = Store.useAction("hospitality");


    // The Current State
    const [ step,              setStep              ] = React.useState("file");
    const [ importText,        setImportText        ] = React.useState("");
    const [ hospitalityFields, setHospitalityFields ] = React.useState([]);
    const [ contactFields,     setContactFields     ] = React.useState([]);
    const [ columns,           setColumns           ] = React.useState([]);

    // The Initial Data
    const initialData = {
        file               : null,
        fileName           : "",
        filePath           : "",
        hospitalityColumns : {},
        contactColumns     : {},
    };


    // Restore the State
    React.useEffect(() => {
        if (open) {
            setElem({ ...initialData });
            setStep("file");
        }
    }, [ open ]);

    // Handles the Edit
    const handleEdit = (data) => {
        if (step === "file") {
            return uploadImport({ ...data, clientID });
        }
        return importHospitality({
            filePath           : data.filePath,
            hospitalityColumns : JSON.stringify(data.hospitalityColumns),
            contactColumns     : JSON.stringify(data.contactColumns),
            clientID,
        });
    };

    // Handles the Column Change
    const handleColumnChange = (field, name, value) => {
        const columns = { ...data[field], [name] : value };
        handleChange(field, columns);
    };

    // Handles the After Submit
    const handleAfterSubmit = (data) => {
        if (step === "columns" && data.success) {
            onSubmit();
            return;
        }

        setElem({
            filePath           : data.filePath,
            hospitalityColumns : Commons.getImportColumns(data.columns, data.hospitalityFields),
            contactColumns     : Commons.getImportColumns(data.columns, data.contactFields),
        });
        setHospitalityFields(data.hospitalityFields);
        setContactFields(data.contactFields);
        setColumns(data.columns);
        setImportText(NLS.format("GENERAL_IMPORT_LINES", data.first, data.last));
        setStep("columns");
    };


    // The Form State
    const {
        data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("hospitality", initialData, handleEdit, handleAfterSubmit, false, open);



    // Do the Render
    const showFile    = step === "file";
    const showColumns = step === "columns";

    return <EditDialog
        open={open}
        icon="import"
        title="HOSPITALITY_IMPORT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        primary="GENERAL_SAVE"
    >
        <DialogMessage
            isHidden={!showFile}
            message="HOSPITALITY_IMPORT_FILE"
        />
        <InputField
            isHidden={!showFile}
            type="file"
            name="file"
            label="MEDIA_SINGULAR"
            value={data.fileName}
            error={errors.file}
            maxSize={process.env.REACT_APP_MAX_SIZE}
            onChange={handleChange}
            onError={setError}
        />

        <DialogMessage
            isHidden={!showColumns}
            message={importText}
        />
        <DialogMessage
            isHidden={!showColumns}
            message="HOSPITALITY_IMPORT_COLUMNS"
        />
        <Columns isHidden={!showColumns}>
            <>
                {hospitalityFields.map(({ key, value }) => <InputField
                    key={key}
                    type="select"
                    name={String(key)}
                    label={value}
                    value={data.hospitalityColumns[key]}
                    onChange={(name, value) => handleColumnChange("hospitalityColumns", name, value)}
                    options={columns}
                />)}
            </>
        </Columns>

        <DialogMessage
            isHidden={!showColumns}
            message="CONTACTS_IMPORT_COLUMNS"
        />
        <Columns isHidden={!showColumns}>
            <>
                {contactFields.map(({ key, value }) => <InputField
                    key={key}
                    type="select"
                    name={String(key)}
                    label={value}
                    value={data.contactColumns[key]}
                    onChange={(name, value) => handleColumnChange("contactColumns", name, value)}
                    options={columns}
                />)}
            </>
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HospitalityImport.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    clientID : PropTypes.number,
};

export default HospitalityImport;
