import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import ErrorLogView         from "./ErrorLogView";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Error Log List
 * @returns {React.ReactElement}
 */
function ErrorLogList() {
    const { fetch, load, loadFilter } = useList("errorLog");

    const { logID } = Navigate.useParams();
    const navigate  = Navigate.useGoto();

    const data = Store.useState("errorLog");
    const { loading, canEdit, list, total, filters, sort } = data;

    const { resolveElem, deleteElem } = Store.useAction("errorLog");

    // The Current State
    const [ checked, setChecked ] = React.useState([]);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Log change
    React.useEffect(() => {
        if (logID) {
            startAction("VIEW", logID);
        }
    }, [ logID ]);


    // Handles the Close
    const handleClose = (update) => {
        endAction();
        navigate("LOGS", "LOG_ERRORS");
        if (update) {
            fetch();
        }
    };

    // Handles the Resolve Submit
    const handleResolve = async () => {
        const result = await resolveElem(elemID || checked);
        if (result.success) {
            setChecked([]);
            fetch();
        }
        endAction();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const result = await deleteElem(elemID || checked);
        if (result.success) {
            setChecked([]);
            fetch();
        }
        endAction();
    };

    // Returns true if the Resolve is hidden
    const hideResolve = (elemID) => {
        const isResolved = Utils.getValue(list, "logID", elemID, "isResolved");
        return !canEdit || isResolved;
    };


    // Do the Render
    const canMulti   = canEdit && checked.length > 0;
    const itemAmount = elemID ? 1 : checked.length;

    return <Main>
        <Header message="ERRORS_LOG_NAME" icon="error-log">
            <ActionList onAction={startAction}>
                <ActionItem
                    isHidden={!canMulti}
                    action="RESOLVE"
                    message="ERRORS_LOG_RESOLVE_TITLE"
                />
                <ActionItem
                    isHidden={!canMulti}
                    action="DELETE"
                />
            </ActionList>
        </Header>

        <Content>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            />
            <Table
                fetch={load}
                sort={sort}
                none="ERRORS_LOG_NONE_AVAILABLE"
                isLoading={loading}
                checked={checked}
                setChecked={setChecked}
                hasCheckAll
                hasFilter
            >
                <TableHead>
                    <TableHeader field="updatedTime" message="GENERAL_TIME"        minWidth="180" maxWidth="180" />
                    <TableHeader field="error"       message="GENERAL_ONE_ERROR"   minWidth="160" maxWidth="160" isTitle />
                    <TableHeader field="environment" message="GENERAL_ENVIRONMENT" minWidth="160" maxWidth="160" isTitle />
                    <TableHeader field="file"        message="GENERAL_FILE"        grow="2" />
                    <TableHeader field="description" message="GENERAL_DESCRIPTION" grow="3" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.logID} elemID={elem.logID}>
                        <TableCell message={elem.updatedTimeText} circle={elem.resolvedColor} />
                        <TableCell message={elem.amountErrorCode} />
                        <TableCell message={elem.environment}     />
                        <TableCell message={elem.fileLine}        />
                        <TableCell message={elem.description}     />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="VIEW"    message="ERRORS_LOG_VIEW_TITLE"    navigate />
                    <TableAction action="RESOLVE" message="ERRORS_LOG_RESOLVE_TITLE" hide={hideResolve} />
                    <TableAction action="DELETE"  message="ERRORS_LOG_DELETE_TITLE"  />
                </TableActionList>
            </Table>
        </Content>

        <ErrorLogView
            open={action.isVCE}
            elemID={elemID}
            onClose={handleClose}
        />
        <ConfirmDialog
            open={action.isResolve}
            icon="check"
            title="ERRORS_LOG_RESOLVE_TITLE"
            message={NLS.pluralize("ERRORS_LOG_RESOLVE_TEXT", itemAmount)}
            onSubmit={handleResolve}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="ERRORS_LOG_DELETE_TITLE"
            message={NLS.pluralize("ERRORS_LOG_DELETE_TEXT", itemAmount)}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default ErrorLogList;
