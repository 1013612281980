import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import FlowConversation     from "../Dialogs/FlowConversation";

// Dashboard
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Flow Conversations
 * @returns {React.ReactElement}
 */
function FlowConversations() {
    const { fetch, load, loadFilter } = useList("flowConversation", "FLOW");

    const navigate = Commons.useGoto();

    const data = Store.useState("flowConversation");
    const { loading, canEdit, list, total, filters, sort } = data;

    const {
        returnConversation, closeConversation, deleteConversation,
    } = Store.useAction("flowConversation");

    // The Current State
    const [ elem,   setElem   ] = React.useState({ id : 0 });
    const [ action, setAction ] = Action.useAction();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        const elem = Utils.getValue(list, "conversationID", elemID);

        if (action.isConversation) {
            navigate("CONVERSATIONS", elem.number);
        } else if (action.isReturn) {
            const response = await returnConversation(elemID);
            if (response.success) {
                fetch();
            }
        } else if (action.isClose) {
            const response = await closeConversation(elemID);
            if (response.success) {
                fetch();
            }
        } else {
            setAction(action);
            setElem(elem);
        }
    };

    // Handles the Close
    const handleClose = (update) => {
        setAction();
        setElem({ id : 0 });
        if (update) {
            fetch();
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        setAction();
        setElem({ id : 0 });
        const result = await deleteConversation(elem.id);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    return <>
        <Filter
            onFilter={loadFilter}
            values={filters}
            hasSearch
        />
        <Table
            fetch={load}
            sort={sort}
            none="CONVERSATIONS_NONE_AVAILABLE"
            isLoading={loading}
            hasFilter
            hasTabs
        >
            <TableHead>
                <TableHeader field="nodeType"        message="FLOWS_NODES_SINGULAR"       isTitle />
                <TableHeader field="createdTime"     message="GENERAL_CREATED_FEM"        maxWidth="200" />
                <TableHeader field="providerName"    message="WIDGETS_PLATFORMS_SINGULAR" />
                <TableHeader field="channelName"     message="CHANNELS_SINGULAR"          />
                <TableHeader field="contactName"     message="CONTACTS_SINGULAR"          />
                <TableHeader field="lastMessageTime" message="CONVERSATIONS_LAST_MESSAGE" maxWidth="200" />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.conversationID} elemID={elem.conversationID}>
                    <TableCell message={elem.nodeName}        />
                    <TableCell message={elem.createdDate}     />
                    <TableCell message={elem.providerName}    />
                    <TableCell message={elem.channelName}     />
                    <TableCell message={elem.contactName}     />
                    <TableCell message={elem.lastMessageDate} />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={handleAction} canEdit={canEdit}>
                <TableAction action="VIEW"         message="CONVERSATIONS_VIEW_TITLE"    />
                <TableAction action="CONVERSATION" message="CONVERSATIONS_GOTO_TITLE"    />
                <TableAction action="RETURN"       message="CONVERSATIONS_RETURN_TITLE"  />
                <TableAction action="CLOSE"        message="CONVERSATIONS_RESOLVE_TITLE" />
                <TableAction action="DELETE"       message="CONVERSATIONS_DELETE_TITLE"  />
            </TableActionList>
        </Table>

        <FlowConversation
            open={action.isView}
            elem={elem}
            onClose={handleClose}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CONVERSATIONS_DELETE_TITLE"
            message="CONVERSATIONS_DELETE_TEXT"
            onSubmit={handleDelete}
            onClose={setAction}
        />
    </>;
}

export default FlowConversations;
