const actions = {

    // Auth
    Session : {
        name    : "Session",
        actions : {
            Login      : "Logged in",
            LoginAs    : "Logged in as user",
            Logout     : "Logged out",
            LogoutAs   : "Logged out as user",
            Disconnect : "Disconnect device",
            Recover    : "Password reset request",
            ResetPass  : "Password modified",
            ChangePass : "Password changed",
            Join       : "Joined the organization",
        },
    },
    Profile : {
        name    : "Profile",
        actions : {
            Edit   : "Profile edited",
            Upload : "Photo uploaded",
            Delete : "Profile deleted",
        },
    },
    Organization : {
        name    : "Organization",
        actions : {
            Accept : "Organization accepted",
            Access : "Organization accessed",
        },
    },



    // admin
    Client : {
        name    : "Client",
        actions : {
            Export  : "Clients exported",
            Create  : "Client created",
            Edit    : "Client edited",
            Approve : "Client approved",
            Delete  : "Client deleted",
        },
    },
    Partner : {
        name    : "Partner",
        actions : {
            Export : "Partners exported",
            Create : "Partner created",
            Edit   : "Partner edited",
            Delete : "Partner deleted",
        },
    },
    Subscription : {
        name    : "Subscription",
        actions : {
            Export : "Subscriptions exported",
            Delete : "Subscription deleted",
        },
    },



    // Client
    Conversation : {
        name    : "Conversation",
        actions : {
            Create     : "Conversation created",
            Edit       : "Conversation edited",
            Template   : "WhatsApp template sent",
            Send       : "Conversation message sent",
            Forward    : "Conversation message forwarded",
            Accept     : "Conversation accepted",
            Return     : "Conversation returned",
            Progress   : "Conversation in course",
            Followup   : "Conversation followup",
            Resolve    : "Conversation resolved",
            Close      : "Conversation closed",
            Open       : "Conversation opened",
            Unread     : "Conversation unread",
            User       : "User assigned to conversation",
            Team       : "Team assigned to conversation",
            Tag        : "Tags updated in conversation",
            Tongue     : "Language assigned to conversation",
            Hotel      : "Hotel assigned to conversation",
            Flow       : "Flow assigned to conversation",
            Generate   : "Conversation text generated",
            Summarize  : "Conversation summarized",
            Delete     : "Conversation deleted",

            NoteCreate : "Conversation note created",
            NoteEdit   : "Conversation note edited",
            NoteDelete : "Conversation note deleted",
        },
    },
    Store : {
        name    : "Store",
        actions : {
            Create         : "Store created",
            Edit           : "Store edited",
            Activate       : "Store activated",
            Publish        : "Store published",
            Clear          : "Store cleared",
            Pause          : "Store paused",
            Delete         : "Store deleted",

            OrderEdit      : "Store order edited",

            ProductCreate  : "Store product created",
            ProductEdit    : "Store product edited",
            ProductDelete  : "Store product deleted",

            CategoryCreate : "Store category created",
            CategoryEdit   : "Store category edited",
            CategoryDelete : "Store category deleted",

            BannerCreate   : "Store banner created",
            BannerEdit     : "Store banner edited",
            BannerDelete   : "Store banner deleted",

            EditOption     : "Store option edited",
        },
    },
    Contact : {
        name    : "Contact",
        actions : {
            Create  : "Contact created",
            Edit    : "Contact edited",
            Export  : "Contacts exported",
            Merge   : "Contacts merged",
            Import  : "Contacts imported",
            Block   : "Contact blocked",
            Unblock : "Contact unblocked",
            Delete  : "Contact deleted",
        },
    },
    Hospitality : {
        name    : "Hospitality",
        actions : {
            Create : "Hospitality created",
            Edit   : "Hospitality edited",
            Delete : "Hospitality deleted",
            Export : "Hospitality exported",
            Import : "Hospitality imported",
        },
    },
    Account : {
        name    : "Account",
        actions : {
            Create : "Account created",
            Edit   : "Account edited",
            Delete : "Account deleted",
            Export : "Accounts exported",
            Import : "Accounts imported",
        },
    },
    Campaign : {
        name    : "Campaign",
        actions : {
            Create          : "Campaign created",
            EditOption      : "Campaign edited",
            Activate        : "Campaign activated",
            Pause           : "Campaign paused",
            Complete        : "Campaign completed",
            Publish         : "Campaign published",
            Clear           : "Campaign cleared",
            Delete          : "Campaign deleted",

            TriggerCreate   : "Campaign trigger created",
            TriggerEdit     : "Campaign trigger edited",
            TriggerDelete   : "Campaign trigger deleted",

            ConditionCreate : "Campaign condition created",
            ConditionEdit   : "Campaign condition edited",
            ConditionDelete : "Campaign condition deleted",

            GroupCreate     : "Group of conditions created",
            GroupEdit       : "Group of conditions edited",
            GroupDelete     : "Group of conditions deleted",

            ExecutionDelete : "Campaign execution deleted",
        },
    },
    User : {
        name    : "User",
        actions : {
            Export       : "Users exported",
            Create       : "User created",
            Invite       : "User invited",
            ResendInvite : "Invitation sent",
            Edit         : "User edited",
            Delete       : "User deleted",
        },
    },
    Team : {
        name    : "Team",
        actions : {
            Create : "Team created",
            Edit   : "Team edited",
            User   : "Teams of an user edited",
            Delete : "Team deleted",
        },
    },



    // Config
    Tag : {
        name    : "Tag",
        actions : {
            Create : "Tag created",
            Edit   : "Tag edited",
            Delete : "Tag deleted",
        },
    },
    Tongue : {
        name    : "Language",
        actions : {
            Create : "Language created",
            Edit   : "Language edited",
            Delete : "Language deleted",
        },
    },
    MsgTemplate : {
        name    : "Message Template",
        actions : {
            Create : "Message template created",
            Edit   : "Message template edited",
            Delete : "Message template deleted",
        },
    },
    Questionnaire : {
        name    : "Frequent questions",
        actions : {
            Create : "Frequent questions created",
            Edit   : "Frequent questions edited",
            Delete : "Frequent questions deleted",
        },
    },
    Schedule : {
        name    : "Opening Hours",
        actions : {
            Create : "Opening hours created",
            Edit   : "Opening hours edited",
            Delete : "Opening hours deleted",
        },
    },
    CustomField : {
        name    : "Custom Field",
        actions : {
            Create : "Custom field created",
            Edit   : "Custom field edited",
            Delete : "Custom field deleted",
        },
    },

    Hotel : {
        name    : "Hotel",
        actions : {
            Add    : "Hotel added",
            Edit   : "Hotel edited",
            Delete : "Hotel deleted",
        },
    },
    Channel : {
        name    : "Channel",
        actions : {
            Add         : "Channel configured",
            Edit        : "Channel edited",
            EditProfile : "Channel profile edited",
            Delete      : "Channel deleted",
        },
    },
    PhoneNumber : {
        name : "Phone number",
        actions : {
            Add     : "Phone number added",
            Import  : "Phone number imported",
            Edit    : "Phone number edited",
            Delete  : "phone number deleted",
        },
    },
    WabaTemplate : {
        name    : "WhatsApp template",
        actions : {
            Create : "WhatsApp template created",
            Edit   : "WhatsApp template edited",
            Import : "WhatsApp template imported",
            Delete : "WhatsApp template deleted",
        },
    },
    Assistant : {
        name    : "IA assistant",
        actions : {
            Create : "IA assistant created",
            Edit   : "IA assistant edited",
            Delete : "IA assistant deleted",
        },
    },
    AssistantModel : {
        name    : "IA assistant model",
        actions : {
            Create : "IA assistant model created",
            Edit   : "IA assistant model edited",
            Delete : "IA assistant model deleted",
        },
    },
    Widget : {
        name    : "Omnichannel widget",
        actions : {
            Create         : "Omnichannel widget created",
            Copy           : "Omnichannel widget copied",
            Edit           : "Omnichannel widget edited",
            Publish        : "Omnichannel widget published",
            Clear          : "Omnichannel widget cleared",
            Delete         : "Omnichannel widget deleted",

            PlatformCreate : "Widget platform created",
            PlatformEdit   : "Widget platform edited",
            PlatformSort   : "Widget platforms sorted",
            PlatformDelete : "Widget platform deleted",
        },
    },
    Flow : {
        name    : "Flow",
        actions : {
            Create            : "Flow created",
            Copy              : "Flow copied",
            Edit              : "Flow edited",
            Publish           : "Flow published",
            Clear             : "Flow cleared",
            Delete            : "Flow deleted",

            NodeCreate        : "Flow node created",
            NodeEdit          : "Flow node edited",
            NodeDrag          : "Flow node dragged",
            NodeMove          : "Flow node copied",
            NodeCopy          : "Flow node moved",
            NodeDelete        : "Flow node deleted",

            EdgeCreate        : "Flow link created",
            EdgeEdit          : "Flow link edited",
            EdgeDelete        : "Flow link deleted",

            SimulationStart   : "Flow simulation started",
            SimulationRestart : "Flow simulation restarted",
            SimulationEnd     : "Flow simulation ended",
        },
    },
    Integration : {
        name    : "Integration",
        actions : {
            Create       : "Integration created",
            Copy         : "Integration copied",
            Edit         : "Integration edited",
            Activate     : "Integration activated",
            Publish      : "Integration published",
            Clear        : "Integration cleared",
            Pause        : "Integration paused",
            Delete       : "Integration deleted",

            ActionCreate : "Integration action created",
            ActionCopy   : "Integration action copied",
            ActionEdit   : "Integration action edited",
            ActionMove   : "Integration action moved",
            ActionDelete : "Integration action deleted",
        },
    },
    Connector : {
        name    : "Connector",
        actions : {
            Create : "Connector created",
            Edit   : "Connector edited",
            Delete : "Connector deleted",
        },
    },
    ConnectorType : {
        name    : "Connector type",
        actions : {
            Edit : "Connector type edited",
        },
    },



    // Support
    Ticket : {
        name    : "Ticket",
        actions : {
            Create        : "Ticket created",
            Edit          : "Ticket edited",
            Delete        : "Ticket deleted",
            Assign        : "Ticket assigned",
            Open          : "Ticket opened",
            Wait          : "Ticket waiting",
            Close         : "Ticket closed",
            Mute          : "Ticket muted",
            Unmute        : "Ticket unmuted",

            MessageAdd    : "Message added",
            MessageEdit   : "Message edited",
            MessageDelete : "Message deleted",
            MessageImage  : "Message image removed",

            NoteAdd       : "Note added",
            NoteEdit      : "Note edited",
            NoteDelete    : "Note deleted",
            NoteImage     : "Note image removed",
        },
    },
    TicketType : {
        name    : "Ticket type",
        actions : {
            Create : "Ticket type created",
            Edit   : "Ticket type edited",
            Delete : "Ticket type deleted",
        },
    },
    TicketWaitlist : {
        name    : "Ticket waitlist",
        actions : {
            Create : "Ticket waitlist created",
            Edit   : "Ticket waitlist edited",
            Delete : "Ticket waitlist deleted",
        },
    },
    Document : {
        name    : "Documentation",
        actions : {
            Create : "Document created",
            Edit   : "Document edited",
            Delete : "Document deleted",
        },
    },



    // Setup
    admin : {
        name    : "Administrator",
        actions : {
            Create : "Administrator created",
            Edit   : "Administrator edited",
            Delete : "Administrator deleted",
        },
    },
    Plan : {
        name    : "Plan",
        actions : {
            Create : "Plan created",
            Edit   : "Plan edited",
            Delete : "Plan deleted",
        },
    },
    addons : {
        name    : "Addon",
        actions : {
            Edit : "Addon edited",
        },
    },
    Currency : {
        name    : "Currency",
        actions : {
            Create : "Currency created",
            Edit   : "Currency edited",
            Delete : "Currency deleted",
        },
    },
    FlowTemplate : {
        name    : "Flow template",
        actions : {
            Create  : "Flow template created",
            Edit    : "Flow template edited",
            Copy    : "Flow template copied",
            Publish : "Flow template published",
            Clear   : "Flow template cleared",
            Delete  : "Flow template deleted",
        },
    },
    IntegrationTemplate : {
        name    : "Integration template",
        actions : {
            Create  : "Integration template created",
            Edit    : "Integration template edited",
            Copy    : "Integration template copied",
            Publish : "Integration template published",
            Clear   : "Integration template cleared",
            Delete  : "Integration template deleted",
        },
    },
    Language : {
        name    : "Language",
        actions : {
            Create : "Language created",
            Edit   : "Language edited",
            Delete : "Language deleted",
        },
    },
    Country : {
        name    : "Country",
        actions : {
            Edit : "Country edited",
        },
    },
    Province : {
        name    : "Province",
        actions : {
            Edit : "Province edited",
        },
    },
    Media : {
        name    : "File",
        actions : {
            Create : "Directory created",
            Upload : "File uploaded",
            Rename : "File renamed",
            Move   : "File moved",
            Delete : "File deleted",
        },
    },



    // Email
    EmailWhiteList : {
        name    : "Email white list",
        actions : {
            Add    : "Email added",
            Edit   : "Email edited",
            Remove : "Email removed",
        },
    },
    EmailQueue : {
        name    : "Email queue",
        actions : {
            Resend : "Email resend",
        },
    },



    // Log
    ProviderLog : {
        name    : "Provider log",
        actions : {
            Delete : "Provider log deleted",
        },
    },
    QueryLog : {
        name    : "Queries",
        actions : {
            Resolved : "Query resolved",
            Delete   : "Query deleted",
        },
    },
    ErrorLog : {
        name    : "Error",
        actions : {
            Resolved : "Error resolved",
            Delete   : "Error deleted",
        },
    },
};

export default actions;
