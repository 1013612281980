import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";
import Commons              from "Utils/Commons";

// The API
import {
    Hospitality, HospitalityIO,
} from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    canFilter     : false,
    list          : [],
    total         : 0,
    elem          : {
        id : 0,
    },
    contact       : {
        id     : 0,
        fields : [],
        tags   : [],
    },
    conversations : [],
    editElem      : {},
    hotels        : [],
    tongues       : [],
    customFields  : [],
    hasInstagram  : false,
    hasTelegram   : false,
    statuses      : [],
    filters       : {},
    hasFilters    : false,
    sort          : {
        filter   : "Booking",
        orderBy  : "arrivalTime",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "HOSPITALITY_LOADING" });
    },

    /**
     * Fetches the Hospitality List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data   = await Hospitality.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "HOSPITALITY_LIST", data });
    },

    /**
     * Fetches a single Hospitality
     * @param {Function} dispatch
     * @param {Number}   hospitalityID
     * @returns {Promise}
     */
    async fetchElem(dispatch, hospitalityID) {
        const data = await Hospitality.getOne({ hospitalityID });
        dispatch({ type : "HOSPITALITY_ELEM", data });
        return data;
    },

    /**
     * Removes the single Hospitality
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async removeElem(dispatch) {
        dispatch({ type : "HOSPITALITY_REMOVE" });
    },

    /**
     * Fetches the Hospitality Edit data
     * @param {Function} dispatch
     * @param {Number}   hospitalityID
     * @param {Number}   clientID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, hospitalityID, clientID) {
        const data = await Hospitality.getEditData({ hospitalityID, clientID });
        dispatch({ type : "HOSPITALITY_EDIT", data });
    },

    /**
     * Edits/Creates a Hospitality
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editHospitality(dispatch, data) {
        return Hospitality.edit(data);
    },

    /**
     * Deletes a Hospitality
     * @param {Function} dispatch
     * @param {Number}   hospitalityID
     * @returns {Promise}
     */
    deleteHospitality(dispatch, hospitalityID) {
        return Hospitality.delete({ hospitalityID });
    },

    /**
     * Uploads the Import File
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    uploadImport(dispatch, data) {
        return HospitalityIO.uploadImport(data);
    },

    /**
     * Imports the Hospitality
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    importHospitality(dispatch, data) {
        return HospitalityIO.import(data);
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "HOSPITALITY_LIST", "HOSPITALITY_ELEM", "HOSPITALITY_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "HOSPITALITY_LOADING":
        return {
            ...state,
            loading       : true,
        };

    case "HOSPITALITY_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            canFilter     : action.data.canFilter,
            list          : Utils.parseList(action.data.list, Commons.parseHospitality),
            total         : action.data.total,
            hotels        : action.data.hotels,
            tongues       : action.data.tongues,
            statuses      : action.data.statuses,
            filters       : action.data.filters,
            hasFilters    : !Utils.isEmpty(action.data.filters),
            sort          : action.data.sort,
        };

    case "HOSPITALITY_ELEM":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            elem          : Commons.parseHospitality(action.data.elem),
            contact       : Commons.parseContact(action.data.contact),
            conversations : Conversations.parseList(action.data.conversations),
        };

    case "HOSPITALITY_EDIT":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            editElem      : Commons.parseHospitality(action.data.elem),
            hotels        : action.data.hotels,
            customFields  : action.data.customFields,
            hasInstagram  : action.data.hasInstagram,
            hasTelegram   : action.data.hasTelegram,
            statuses      : action.data.statuses,
        };

    case "HOSPITALITY_REMOVE":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            elem          : { ...initialState.elem },
            contact       : { ...initialState.contact },
            conversations : [],
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
