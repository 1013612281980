import React                from "react";
import PropTypes            from "prop-types";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";



/**
 * The Users Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UsersContent(props) {
    const { isHidden, type } = props;

    const elemID = Navigate.useOneParam(type);

    const { loading, edition, list } = Store.useState("dashboardUser");
    const { fetchList } = Store.useAction("dashboardUser");


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ update, setUpdate ] = React.useState(0);


    // Handle the Auto-update
    React.useEffect(() => {
        if (!isHidden) {
            fetchList(type, elemID);
            Utils.setUpdateTimeout(timerRef, setUpdate, update, 20);
        }
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, [ isHidden, update, edition ]);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Table
        isLoading={loading}
        none="USERS_NONE_AVAILABLE"
        hasTabs
        noSorting
    >
        <TableHead>
            <TableHeader field="name"                  message="USERS_SINGULAR"               />
            <TableHeader field="currentProgress"       message="DASHBOARD_TOTAL_PROGRESS"     align="center" maxWidth="80"  />
            <TableHeader field="currentFollowup"       message="DASHBOARD_TOTAL_FOLLOWUP"     align="center" maxWidth="110" />
            <TableHeader field="resolved"              message="DASHBOARD_TOTAL_RESOLVED"     align="center" maxWidth="70"  />
            <TableHeader field="avgFirstMessage"       message="DASHBOARD_AVG_FIRST_MESSAGE"  align="center" maxWidth="170" />
            <TableHeader field="avgMessage"            message="DASHBOARD_AVG_MESSAGE"        align="center" maxWidth="140" />
            <TableHeader field="avgResolved"           message="DASHBOARD_AVG_RESOLVED"       align="center" maxWidth="140" />
            <TableHeader field="percentQualifications" message="CONVERSATIONS_QUALIFICATIONS" align="center" maxWidth="100" />
        </TableHead>
        <TableBody>
            {list.map((elem) => <TableRow key={elem.id} elemID={elem.id}>
                <TableCell message={elem.name}                  />
                <TableCell message={elem.currentProgress}       />
                <TableCell message={elem.currentFollowup}       />
                <TableCell message={elem.resolved}              />
                <TableCell message={elem.avgFirstMessage}       />
                <TableCell message={elem.avgMessage}            />
                <TableCell message={elem.avgResolved}           />
                <TableCell message={elem.percentQualifications} />
            </TableRow>)}
        </TableBody>
    </Table>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UsersContent.propTypes = {
    isHidden : PropTypes.bool.isRequired,
    type     : PropTypes.string.isRequired,
};

export default UsersContent;
